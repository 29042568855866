import { curencyFormater } from "../utils/functions";

const DisplayAsset = ({assets, t, landscape}) => {
    // console.log(assets[0].rubriques[0].posts)
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white"> {t("FIN_STATEMENT.ASSETS")}</h3>
            </div>
            <div className="table-responsive table-stripe">
                <table className="table table-hover table-bordered">
                    <thead className="" style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            {/* {landScape &&
                            <th scope="row">Ref</th> } */}
                            <th scope="col">{landscape?(<>{t("OTHERCRRINFO.REF")}</>):(<>{t("FIN_STATEMENT.LABEL")}</>)}</th>
                            <th scope="col"style={{textAlign:"right"}}>{t("FIN_STATEMENT.GROSS")}</th>
                            <th scope="col"style={{textAlign:"right"}}>{t("FIN_STATEMENT.GROSS")}</th>
                            <th scope="col"style={{textAlign:"right"}}>Net</th>
                            <th scope="col"style={{textAlign:"right"}}>Net2</th>
                            <th scope="col"style={{textAlign:"right"}}>Net3</th>
                        </tr>
                    </thead>
                    {assets?.map((value)=>(
                        <tbody key={value.reference}>
                            {value.rubriques?.map((rub)=>
                                (<>
                                    {rub.posts?.map((post)=>(
                                        <tr key={post?.reference}>
                                            <td>{landscape?(<>{post?.reference}</>):(<>{t(`ASSETS.${post?.reference}`)}</>)}</td>
                                            {/* <td ></td> */}
                                            {/* <td >{post?.reference==="AP"||post?.reference==="AL"?post?.label:post?.reference}</td> */}
                                            <td style={{textAlign:"right"}}>{curencyFormater(post?.gross?post?.gross:0.0)}</td>    
                                            <td style={{textAlign:"right"}}>{curencyFormater(post?.amort?post?.amort:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post?.net?post?.net:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post?.net1?post?.net1:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post?.net2?post?.net2:0.0)}</td>
                                        </tr>
                                    ))}
                                    <tr key={rub.reference} style={{backgroundColor:rub.posts?.length!==0?"var(--tp-common-gray)":"inherit", color:rub.posts?.length!==0?"black":"inherit"}}>
                                    {/* {landScape &&<td >{rub.reference}</td>} */}
                                        <td >{landscape?(<>{rub.reference}</>):(<>{t(`ASSETS.${rub.reference}`)}</>)}</td>
                                        {/* <td  style={{textAlign:"right"}}><input className="form-control mt-0 mb-0" id="firstName1" type="text" placeholder="1000000000000" style={{borderStyle:"none", pointerEvents:rub.posts?.length!==0?"none":"inherit", background:rub.posts?.length!==0?"inherit":""}} disabled={rub.posts?.length!==0}/></td> */}
                                        <td  style={{textAlign:"right"}}>{curencyFormater(rub.gross?rub.gross:0)}</td>
                                        <td  style={{textAlign:"right"}}>{curencyFormater(rub.amort?rub.amort:0)}</td>
                                        <td  style={{textAlign:"right"}}>{curencyFormater(rub.net?rub.net:0)}</td>
                                        <td  style={{textAlign:"right"}}>{curencyFormater(rub.net1?rub.net1:0)}</td>
                                        <td  style={{textAlign:"right"}}>{curencyFormater(rub.net2?rub.net2:0.0)}</td>
                                    </tr>
                                </>)
                            )}
                            <tr key={value.reference} style={{backgroundColor:value.rubriques?.length!==0 || value.reference==="BZ"?"#8da9c4":"inherit", color:value.rubriques?.length!==0 || value.reference==="BZ"?"white":"inherit", textTransform:value.rubriques?.length!==0 || value.reference==="BZ"?"uppercase":"inherit"}}>
                            {/* {landScape &&<td>{value.reference}</td>} */}
                                <td>{landscape?(<>{value.reference}</>):(<>{t(`ASSETS.${value.reference}`)}</>)}</td>
                                {/* <th scope="col" style={{textAlign:"right"}}><input className="form-control mt-0 mb-0" id="firstName1" type="text" placeholder="1000000000000" style={{borderStyle:"none", pointerEvents:value.rubriques?.length!==0||value.reference==="BZ"?"none":"inherit", background:value.rubriques?.length!==0||value.reference==="BZ"?"inherit":""}} disabled={value.rubriques?.length!==0||value.reference==="BZ"}/></th> */}
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.gross?value.gross:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.amort?value.amort:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net?value.net:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net1?value.net1:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net2?value.net2:0)}</th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    );
}

export default DisplayAsset;