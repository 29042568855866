import { curencyFormater } from "../../../components/utils/functions";

const FooNotePane = ({footNoteData,t}) => {
    return (
    <div className="col-xl-6 mb-5">
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">{t(`FOOTNOTE.${footNoteData?.reference}`)}</h3>
            </div>
            <div className="table-responsive table-strip">
                <table className="table table-hover table-bordered">
                    <thead style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            <th scope="col">{t("FIN_STATEMENT.LABEL")}</th>
                            <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net</th>
                            <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net2</th>
                            <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net3</th>
                        </tr>
                    </thead>
                        
                    <tbody>
                        {footNoteData.posts.map((post)=>(
                            <tr key={post.reference}>
                                <td>{t(`FOOTNOTE.${post.reference}`)}</td>
                                <td style={{textAlign:"right", fontSize:"10pt"}}>{curencyFormater(post.net)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(post.net1)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(post.net2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}
export default FooNotePane;