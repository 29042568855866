import Select from "react-select";
import { inLineSelectStyles2} from "../../../components/utils/reactSelect";
const CRRFormInit = ({setFile1, setFile2, file1, file2, extract_data, t}) => {
    return (
        <>
            <div className="row mb-2" style={{display:"flex", flexWrap:"wrap", gap:"auto"}}>
                {/* <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-append" style={{backgroundColor:"#1D4351"}}><span className="input-group-text" id="basic-addon2" style={{backgroundColor:"#1D4351", borderColor:"#1D4351", color:"white"}}>{t("textYear_")}</span></div>
                        <Select 
                            className="basic-single"
                            menuPortalTarget={document.body}
                            styles={inLineSelectStyles2}
                            options={[
                                {label:`${annee-1}`, value:`${annee-1}`},
                                {label:`${annee-2}`, value:`${annee-2}`},
                                {label:`${annee-3}`, value:`${annee-3}`},
                                {label:`${annee-4}`, value:`${annee-4}`},
                                {label:`${annee-5}`, value:`${annee-5}`}
                            ]}
                            value={year}
                            onChange={(e)=>setYear(e.value)}
                            />
                    </div>
                </div> */}
                {/* <div className="col-md-4 ">
                    <div className="input-group mb-3">
                        <div className="input-group-append" style={{backgroundColor:"#1D4351"}}><span className="input-group-text" id="basic-addon2" style={{backgroundColor:"#1D4351", borderColor:"#1D4351", color:"white"}}>{t("textPeriod")}</span></div>
                        <Select 
                            className="basic-single"
                            menuPortalTarget={document.body}
                            styles={inLineSelectStyles2}
                            options={[
                                {label:"12", value:"12"},
                                {label:"6", value:"6"},
                                {label:"3", value:"3"}
                            ]} 
                            value={period}
                            onChange={(e)=>setPeriod(e.value)}
                            />
                    </div>
                </div> */}
            </div>
            <div className="col-xl-12 mt-3 mb-3">
                <div className="card text-left o-hidden">
                    <h5 className="accordion-header" id="headingOne">
                        <div className="card-header d-flex align-items-center border-0 my-header">
                            {t("FIN_STATEMENT.UPLOAD")} <span className="float-right"><button><i className="nav-icon i-coffee font-weight-bold"></i></button></span>
                        </div>
                    </h5>
                    <div className="card-body" style={{backgroundColor:"#ffff"}}>
                        <div className="row mb-2 p-4">
                            <div className="col-6">
                                <div className="text-left">
                                    <div className="card-body">
                                        <h4 className="card-title">{t("FIN_STATEMENT.YEARE")} n-1</h4>
                                        <form className="dropzone" id="single-file-upload" action="#">
                                            <div className="fallback">
                                                <input 
                                                    name="file2" 
                                                    type="file" 
                                                    defaultValue={file2} 
                                                    onChange={(e)=>setFile1(e.target.files[0])}
                                                    accept=".xlsx"
                                                    />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="text-left">
                                    <div className="card-body">
                                        <h4 className="card-title">{t("FIN_STATEMENT.YEARE")} n</h4>
                                        <form className="dropzone" id="single-file-upload" action="#">
                                            <div className="fallback">
                                                <input 
                                                    name="file1" 
                                                    type="file" 
                                                    defaultValue={file1} 
                                                    onChange={(e)=>setFile2(e.target.files[0])}
                                                    // accept=".pdf, .xls, .xlsx"
                                                    accept=".xlsx"
                                                    />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        {/* <div className="text-left"> */}
                                <button className={file1 && file2?"btn ripple m-1":"btn ripple m-1 disabled"} 
                                style={{float:"right",backgroundColor:"#1D4361", color:"white"}} type="submit" onClick={extract_data}>Extract data</button>
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CRRFormInit;