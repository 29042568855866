import { curencyFormater } from "../../../components/utils/functions"

const BusinessRevenueTable = ({businessRevenues, t}) => {
    console.log(businessRevenues)
    return (<div className="card text-left o-hidden">
        <div className="card-header d-flex align-items-center border-0 my-header">
            <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.ACTIVTYBREAKDOWN")}</h3>
        </div>
        <div className="table-responsive table-strip">
                <table className="table table-hover table-bordered">
                    <thead>
                        {businessRevenues.length!==0 &&
                        <tr style={{backgroundColor:"var(--tp-common-gray)"}}>
                            <th>Activity</th>
                            <th>Revenue</th>
                        </tr>
                        }
                    </thead>
                    <tbody>
                        {businessRevenues.map((val, index)=>(
                            <tr key={index}>
                                <td >
                                    {String(t(`ACTIVITIES.${val.business}`)).substring(0,35)}...
                                </td>
                                <td style={{textAlign:"right"}}>
                                    {curencyFormater(val.revenue)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </div>
    </div>)
}
export default BusinessRevenueTable