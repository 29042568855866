import { useState } from "react";
import BreadCrumb from "../../components/breadcrumb"
import useSWR from "swr";
import { MockData } from "../../mock-data/data";
import { Link } from "react-router-dom";
import { API, FFAPI } from "../../services/axios";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import NoTransaction from "../../components/no-transaction/emptypage";
const fetcher = (url) => FFAPI.get(url).then((res) => { console.log(res.data.results); return res.data.results});

const SMEFForcastingList = () =>{
    const {data: ffList, loadingFF} = useSWR(URLS.FF.list(), fetcher)
    console.log(ffList)
    return (
        <>
            <BreadCrumb title1="FF-SME" title2="List"/>
            <>
            {loadingFF?(<LoaderPage/>):
            (
            <>{(ffList=== undefined || ffList?.length===0)?(
                <NoTransaction transaction={"FF"}/>
            ):
            (<div className="row">
                <div className="row mb-4">
                    <div className="col-md-12 mb-3">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                <h3 className="w-50 float-left card-title m-0 text-white">Financial Forcasting list</h3>
                            </div>
                            <div>
                                <div className="table-responsive">
                                    <table className="table table-hover" id="alternative_pagination_table" style={{width:"100%"}}>
                                        <thead className="">
                                            <tr>
                                                <th scope="col">Reference</th>
                                                <th scope="col">Year</th>
                                                <th scope="col">Period</th>
                                                <th scope="col">Plug</th>
                                                {/* <th scope="col">Activity</th> */}
                                                <th scope="col">Date</th>
                                                {/* <th scope="col">Date</th> */}
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ffList?.map((forecast)=>(
                                                <tr key={forecast.id}>
                                                    <td><span className="badge badge-info  m-1">{forecast?.reference}</span></td>
                                                    <td style={{padding:"auto"}}>{forecast?.first_year}</td>
                                                    <td>{forecast?.years}</td>
                                                    <td>{forecast?.plug}</td>
                                                    {/* <td>{forecast?.activity.title}</td> */}
                                                    {/* <td>{forecast?.head_office.name}</td> */}
                                                    <td>{(new Date(forecast?.created_at)).toLocaleString()}</td>
                                                    <td style={{alignContent:"right"}}>
                                                        <Link to={`/f-forcasting/details/${forecast?.id}`}><button className="btn btn-primary" type="button" style={{marginRight:10}}><i className="nav-icon i-Eye"></i></button></Link>
                                                        {/* <button className="btn btn-danger" type="button"><i className="nav-icon i-Close-Window"></i></button> */}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
            </>)}
            </>
        </>
    )
}

export default SMEFForcastingList;