import { useEffect, useState } from "react"
import useSWR from "swr";
import {Link, Outlet} from "react-router-dom"
import BillComponent from "./bill-component";
import BreadCrumb from "../../components/breadcrumb";
import { toast } from "react-toastify";
import { URLS } from "../../services/urls";
import { API } from "../../services/axios";
import LoaderPage from "../../components/loader";
import PackageComponent from "./package-component";
import axios from "axios";
import { getUserSubscribtions, setUserSubscribtions } from "../../components/utils/functions";
import SubsValidation from "./subscribtionValidationModal";
import { useTranslation } from "react-i18next";
import CardComponent from "./card-component";
import { MASTERCARD_LOGO, VERVE_LOGO, VISA_LOGO } from "../../components/utils/consts";
import CardForm from "./card-form";
import NoTransaction from "../../components/no-transaction/emptypage";
const fetcher2 = (url) => API.get(url).then(res => {console.log(res.data.datas); return res.data.datas});
const fetcher = (url) => API.get(url).then(res => res.data.datas);
const BillingPage = () =>{
    const { t } = useTranslation()
    const [subscribing, SetSubscribing] = useState(false);
    const {data: billList, isLoading} = useSWR(URLS.PAYMENT.INVOICE.list(), fetcher);
    const {data: packageList, isLoading2} = useSWR(URLS.PAYMENT.PACKAGES.list(), fetcher);
    const {data: credentialList, isLoading3} = useSWR(URLS.PAYMENT.CREDENTIALS.list(), fetcher2);
    const [del, setDel] = useState(false);
    const [pack, setPack] = useState();
    const [prevPack, setPrevPack] = useState(null);
    const subscribe = (id) => {
        let subscribtions = getUserSubscribtions();
        SetSubscribing(true);
        API.post(URLS.PAYMENT.SUBSCRIBTIONS.create(id))
        .then((resp)=>{
            let data=resp.data.datas;
            toast.success("You have successfully subcribed to a new package",{position:toast.POSITION.TOP_CENTER})
            subscribtions.push(data.service_ref);
            setUserSubscribtions(subscribtions);
            SetSubscribing(false)
        }).catch((err)=>{
            toast.error("Unable to get your subscribtion done , please try again later", {position: toast.POSITION.TOP_CENTER})
            SetSubscribing(false)
        })
    }

    const handleOpenModel = (cancel, pkge)=>{
        setDel(cancel);
        setPack(pkge);
        window.$("#subsValiationModal").modal("show");
    }

    const handleUpdate = (id, ref) => {
        SetSubscribing(true);
        API.post(URLS.PAYMENT.SUBSCRIBTIONS.update(pack.id), {reference:prevPack})
        .then((resp)=>{
            toast.success("You have successfully updated to a new package",{position:toast.POSITION.TOP_CENTER})
            SetSubscribing(false)
            window.$("#subsValiationModal").modal("hide");
        }).catch((err)=>{
            toast.error("Unable to update your package , please try again later", {position: toast.POSITION.TOP_CENTER})
            SetSubscribing(false)
            window.$("#subsValiationModal").modal("hide");
        })
        // console.log(`updating..... from ${prevPack}`)
    }
    const handleUnsubscribe = (id) => {
        SetSubscribing(true);
        API.post(URLS.PAYMENT.SUBSCRIBTIONS.delete(pack.id))
        .then((resp)=>{
            toast.success("You have successfully unsubscribe to package",{position:toast.POSITION.TOP_CENTER})
            SetSubscribing(false)
            window.$("#subsValiationModal").modal("hide");
        }).catch((err)=>{
            toast.error("Unable to unsubscribe , please try again later", {position: toast.POSITION.TOP_CENTER})
            SetSubscribing(false)
            window.$("#subsValiationModal").modal("hide");
        })
        // console.log("Unsubscribing.....")
    }
 
    return (
        <>
        {(isLoading || isLoading2 || isLoading3)?(<LoaderPage/>):(
            <>
            <div className={subscribing?"main-content disabled":"main-content"}>
            <SubsValidation del={del} pack={pack} onUnSubscribe={handleUnsubscribe} onUpdate={handleUpdate} prevPack={prevPack}/>
            <BreadCrumb title1="Billing" title2="Details" link="/billing"/>
                {/* <div className="row"> */}
                    <div className="ul-widget__head">
                        <div className="ul-widget__head-toolbar" style={{width:"100%"}}>
                            <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tabs-line ul-widget-nav-tabs-line" role="tablist" style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#billing-overview" role="tab">{t("textBilling")}</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#payment-settings" role="tab">Payment Settings</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#billing-reference" role="tab">Subscribtions</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="ul-widget__body">
                        <div className="tab-content">
                            <div className="tab-pane active" id="billing-overview"style={{background:"#fff", borderStyle:"none"}}>
                                {(billList===undefined || billList?.length===0)?(
                                    <NoTransaction transaction={"BILL"}/>
                                ):(
                                    <>
                                        <div className="row pt-10 mb-10">
                                            <h1 className="text-center">Billing</h1>
                                        </div>
                                        <div className="accordion" id="accordionRightIcon">
                                            {billList?.map((bill)=>(
                                        <div className="mb-3" key={bill.ref}>
                                            <BillComponent bill={bill} loading={SetSubscribing}/>
                                        </div>
                                    ))}
                                    </div>
                                    </>
                                )}
                            </div>
                            <div className="tab-pane" id="payment-settings" style={{background:"#ffff", borderStyle:"none"}}>
                                {/* <div className="row pt-10 mb-10">
                                    <h1 className="text-center">Payment Settings</h1>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-6 col-xl-6 mb-4">
                                    {(billList===undefined || billList?.length===0)?(
                                        <NoTransaction transaction={"CARD"}/>
                                    ):(
                                        <>
                                        <div className="card" style={{background:"white"}}>
                                            <div className="card-body" style={{background:"#ffff"}}>
                                                <div className="ul-widget__head pb-20 v-margin">
                                                    <div className="ul-widget__head-label">
                                                        <h3 className="ul-widget__head-title">Registered Cards</h3>
                                                    </div>
                                                </div>
                                                <div className="ul-widget__body">
                                                    <div className="ul-widget1">
                                                        {credentialList?.map((cred)=>(
                                                            <CardComponent creds={cred} key={cred.id}/>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>)}     
                                    </div>
                                    <div className="col-lg-6 col-xl-6 mb-4">
                                        <CardForm/>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="billing-reference" style={{background:"#fff", borderStyle:"none"}}>
                            <section className="tp-team-breadcrumb-area pb-70">
                                <div className="row pt-10 mb-10">
                                    <h1 className="text-center">Subscribtion packages</h1>
                                </div>
                                <div className="container-fluid">
                                    <div className="row">
                                        {packageList?.map((pack)=>(
                                            <PackageComponent pack={pack} subscribe = {subscribe} key={pack.id} openModal={handleOpenModel} update={handleUpdate} unSubscribe={handleUnsubscribe} setPrevPack={setPrevPack}/>))
                                        }
                                    </div>
                                </div>
                            </section>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </>)}
        </>
    )
}
export default BillingPage;