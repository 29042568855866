import { useEffect, useMemo, useState } from "react";
import axios from 'axios'
import BreadCrumb from "../../components/breadcrumb"
import useSWR from "swr";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import { API } from "../../services/axios";
import { Link } from "react-router-dom";
import BarChart from "../../components/chart/BarChart";
import AddUser from "./form";
import NoTransaction from "../../components/no-transaction/emptypage";

const fetcher = (url) => API.get(url).then(res => {return res.data.results});
const pFetcher = (url) => API.get(url).then((res) => {return res.data.results})
const MembersPage = () => {
    const [page, setPage] = useState(1);
    const {data: partners, isLoading, mutate} = useSWR(URLS.USERS.list(page), fetcher);
    const {data: countries, isLoadingC} = useSWR(URLS.PARAMS.COUNTRY.ohada(20), pFetcher);
    const {data: cities, isLoadingCC} = useSWR(URLS.PARAMS.COUNTRY.CYTIES.ohada(1000), pFetcher);
    const usersList = partners?.results || [];
    const [filteredUsers, setFilteredUsers] = useState([])
    const [toEdit, setToEdit] = useState();
    useEffect(()=>{
        setFilteredUsers(usersList)
    },[])
    const onSuccess = () => {
        mutate()
      }
    const handleEdit = () => {
        setToEdit(null)
        window.$("#manageUser").modal("show");
    }

    return (
        <>
            {(isLoading || isLoadingC || isLoadingCC)?(<LoaderPage/>):
            <div>
                <AddUser toEdit={toEdit} onSuccess={onSuccess} countries={countries} cities={cities}/>
                <BreadCrumb title1="Members Accounts" title2="Home"/>
                {partners.length<=0?
                (<NoTransaction transaction={"MEMBER"} addFunction={handleEdit}/>):
                (<div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            {/* <div className="col-xl-12">
                                <button className="btn my-header mb-4 float-left">
                                    Add User
                                </button>
                            </div> */}
                            <div className="col-md-12">
                                <div className="card o-hidden mb-4">
                                    <div className="card-header d-flex align-items-center border-0 my-header">
                                        <h3 className="w-50 float-left card-title m-0 text-white">Most Active Users</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table text-center" id="user_table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Avatar</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Last Login</th>
                                                    <th scope="col">Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partners?.results?.map((user, index)=>(
                                                <tr key={user.id}>
                                                    <td><img className="rounded-circle m-0 avatar-sm-table" src={user.picture} alt="" /></td>
                                                    <td>{user.first_name} {user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td><span className="">{(new Date(user.last_login)).toLocaleString()}</span></td>
                                                    <td>
                                                        <Link className="text-success mr-2" to={`/group-members/details/${user.id}`}><i className="nav-icon i-Eye font-weight-bold"></i></Link>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card o-hidden mb-4">
                                    <div className="card-header d-flex align-items-center border-0 my-header">
                                        <h3 className="w-50 float-left card-title m-0 text-white">Rescently added users</h3>
                                        <div className="dropdown dropleft text-right w-50 float-right">
                                            <button className="btn " style={{background:"none"}}id="dropdownMenuButton1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="nav-icon i-Gear-2"></i></button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <Link className="dropdown-item" onClick={()=>handleEdit()}>Add new user</Link>
                                                <Link className="dropdown-item" to="/group-members/list">View All users</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table text-center" id="user_table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Avatar</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Last Login</th>
                                                    <th scope="col">Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partners?.results?.map((user, index)=>(
                                                <tr key={user.id}>
                                                    <td><img className="rounded-circle m-0 avatar-sm-table" src={user.picture} alt="" /></td>
                                                    <td>{user.first_name} {user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td><span className="">{(new Date(user.last_login)).toLocaleString()}</span></td>
                                                    <td>
                                                        <Link className="text-success mr-2"  to={`/group-members/details/${user.id}`}><i className="nav-icon i-Eye font-weight-bold"></i></Link>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-12">
                        <div className="card mb-4 o-hidden">
                            <div className="card-body ul-card__widget-chart">
                                <div className="card-header d-flex align-items-center border-0 my-header">
                                    <h5 className="heading text-white">Users' Activities</h5>
                                </div>
                                <BarChart/>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>)}
            </div>
            }
        </>
    )
}
export default MembersPage