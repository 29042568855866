import ParamsWithOptions from "./ParametersWithOptions"

const STDAssumptionPage = ({parameters, setParameter, plug, year1, horizon}) => {
    console.log(year1)
    const longTermRepayList = new Array(horizon).fill(year1)
    let longTermRepayList_ = new Array(horizon).fill(year1)
    const setLongTermRepay=(value, index)=>{
        console.log(value)
        longTermRepayList_[index] = value;
        setParameter('longTermRepay', longTermRepayList_);
    }
    return <div className="row mb-4">
        {(plug && String(plug).toLowerCase().includes("constant"))?
            <div className="row">
                    {/* For constant plug */}
                <h3 className="text-center mb-2 mt-2">Short term Debt Constant over the periods</h3>
            </div>:
            <>
                {(plug && String(plug).toLowerCase().includes("dynamic"))&&
                <div className="row mb-2">
                    {/* For dynamic std plug */}
                    <h3 className="text-center mb-2 mt-2">Short term Debt Constant over period</h3>
                </div>}
                {(plug && String(plug).toLowerCase()==="std")&&
                <div className="row">
                    {/* For std plug */}
                    <h3 className="text-center mb-2 mt-2">Short term debt with no additionnal constraint</h3>
                </div>
                }
            </>}
            <div className="row">
                {(String(plug).toLowerCase().includes("constant")||String(plug).toLowerCase().includes("dynamic"))&&
                <div className="mb-4 col-xl-4">
                    <ParamsWithOptions parameters={parameters} setParameter={setParameter} plug={plug}/>
                </div>}
                <div className="mb-4 col float-left">
                    <label>Short term rate <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('shortTermRate',parseFloat(e.target.value))}} value={parameters['shortTermRate']}/>
                </div>
                <div className="mb-4 col float-right">
                    <label>Long term Interest <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('longTermInt',parseFloat(e.target.value))}} value={parameters['longTermInt']}/>
                </div>
                {
                    (plug && String(plug).toLowerCase().includes("dynamic"))&&
                    <>
                        <div className="mb-4 col float-left">
                            <label>Long term rate <span className="ml-2 text-danger text-14">*</span></label>
                            <input type="number" onChange={(e)=>{setParameter('longTermRate',parseFloat(e.target.value))}} value={parameters['longTermRate']}/>
                        </div>
                        <div className="mb-4 col-xl-2 float-right">
                            <label>Long term Repay <span className="ml-2 text-danger text-14">*</span></label>
                            <input type="number" onChange={(e)=>{setLongTermRepay(parseFloat(e.target.value), 0)}} 
                                    placeholder={longTermRepayList_[0]} key={0} className="mt-2"/>
                            {horizon>=2&&<input type="number" onChange={(e)=>{setLongTermRepay(parseFloat(e.target.value), 1)}} 
                                    placeholder={longTermRepayList_[1]} key={1} className="mt-2"/>}
                            {horizon>=3&&<input type="number" onChange={(e)=>{setLongTermRepay(parseFloat(e.target.value), 2)}} 
                                    placeholder={longTermRepayList_[2]} key={2} className="mt-2"/>}
                            {horizon>=4&&<input type="number" onChange={(e)=>{setLongTermRepay(parseFloat(e.target.value), 3)}} 
                                    placeholder={longTermRepayList_[3]} key={3} className="mt-2"/>}
                            {horizon>5&&<input type="number" onChange={(e)=>{setLongTermRepay(parseFloat(e.target.value), 4)}} 
                                    placeholder={longTermRepayList_[4]} key={4} className="mt-2"/>}
                        </div>
                    </>
                }
            </div>
    </div>
}
export default STDAssumptionPage;