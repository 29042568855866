import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { percentageFormater } from "../../../components/utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { useTranslation } from "react-i18next";
import STDAssumptionPage from "./STDAssumptions";
import STDLTDAssumptionsPage from "./STSLTDAssumptions";

const AsumptionTable = ({assumptions, forcastingPlug, setForcastingPlug, setParameter, horizon, year1}) =>{
    const {t} = useTranslation();
    return (
        <div className="mb-4">
            <div className="card">
                    <div className="card-header">
                <div className="row justify-content-between">
                    <div className="col-xl-9"></div>
                    <div className="mb-4 col-xl-3 float-right">
                        <label>Update Your plug <span className="ml-2 text-danger text-14">*</span></label>
                        <FontAwesomeIcon icon={faQuestionCircle} className="float-right" data-toggle="tooltip" data-placement="bottom"  title="Describtion in tooltip"/>
                        <select className="form-control" onChange={(e)=>{setForcastingPlug(e.target.value)}} value={forcastingPlug}>
                            <option value="">Select </option>
                            <option value={'STD'}>Short Term Debt</option>
                            <option value={'STD_CONSTANT'}>Constant Short Term Debt</option>
                            <option value={'STD_DYNAMIC'}>Dynamic Short Term Debt</option>
                            <option value={'STD_LTD'}>Short term Debt and Long Term Debt</option>
                            <option value={'STD_LTD_EQ'}>Short term Debt, long Term and Equity</option>
                        </select>
                    </div></div>
                </div>
                {forcastingPlug&&
                    <div className="card-body">
                    {
                        forcastingPlug?.includes("STD_LTD")?(
                            <STDLTDAssumptionsPage plug={forcastingPlug} setParameter={setParameter} parameters={assumptions}/>
                        ):(<STDAssumptionPage STDLTDAssumptionsPage plug={forcastingPlug} setParameter={setParameter} parameters={assumptions} year1={year1} horizon={horizon}/>)
                    }
                    </div>}
            </div>
        </div>
    )
}

export default AsumptionTable;