import axios from "axios";
import { useEffect, useState } from "react"
import { toast } from "react-toastify";

import { URLS } from "../../services/urls";

import BreadCrumb from "../../components/breadcrumb";
import LoaderPage from "../../components/loader";
import CRRFormInit from "./components/formInit";
import { useTranslation } from "react-i18next";
import { API } from "../../services/axios";
import DisplayAsset from "../../components/financial_statement_display/assets_display";
import DisplayLiability from "../../components/financial_statement_display/display_liabilities";
import DisplayIncomeStatement from "../../components/financial_statement_display/display_income";
import DisplayCashFlow from "../../components/financial_statement_display/display_cashflow";
import FootNoteForm from "./components/footNoteForm";
import BusinessRevenueTable from "./components/businessRevenuesTable";
import RDExpnesesForm from "./components/rdExpensesForm";
import CompanyDetailsForm from "./components/companyDetailsForm";
import QualitativeDataForm from "./components/qualitativeDataForm";
import CountryRevenueForm from "./components/countriesRevenuForm";
import LeasingForm from "./components/leasingForm";
import DebtDetailsForm from "./components/debtDetailsForm";
import { getCountryCurrency, setLocalCurrency } from "../../components/utils/functions";
import { useNavigate } from "react-router-dom";

const SMECRRForm = () => {
    const {t} = useTranslation();
    const navigage = useNavigate();
    const annee=(new Date().getFullYear())-1;
    
    const [qualitativeData, loadQualitativeData] = useState([]);
    const [activitiesList, setActivitiesList] = useState([])
    const [legalStatusList, setlegalStatusList] = useState();
    const [countriesList, setCountriesList] = useState();
    const [footNotesList, setFootNotesList] = useState([]);
    const [landScape, setLandScape] = useState(false);

    const [legalStatus, setlegalStatus] = useState(null);
    const [firstYear, setFirstYear] = useState(null);

    const [loading, setLoading] = useState(false);
    const [extracted, setExtracted] = useState(false);

    // form data
    const [file1, setFile1]=useState(null);
    const [file2, setFile2]=useState(null);

    // data after extraction
    const [headOffice, setHeadOffice] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [numberOfShares, setNumberOfShares] = useState(0);
    const [mainActivity, setMainActivity] = useState(null);
    const [businessRevenues, setbusinessRevenues] = useState([]);
    const [rdAmortPeriod, setRdAmortPeriod] = useState(0);
    const [staff, setStaff] =useState(null);
    const [assets, setAssets] = useState([]);
    const [liabilities, setLiabilities] = useState([]);
    const [incomeStatement, setIncomeStatement] = useState([])
    const [cashflow, setCashflow] = useState([]);
    const [rdInputMethod,setRdInputMethod] = useState(null);
    const [rdExpenses, setRdExpenses] = useState([])
    const [olExpenses, setOlExpenses] = useState([]);
    const [taxRate, setTaxRate] = useState(33.0);
    const [vatRate, setVatRate] = useState(19.25);
    const [countryRevenu, setCountryRevenu] = useState([])
    const [olAmortPeriod, setOlAmortPeriod] = useState(0);
    const [visa,setVisa] = useState(false);
    const [underControl, setUndercontrol] = useState(false)
    
    const [average_maturity_of_debt, setaverage_maturity_of_debt] = useState(10);
    const [debtEstimationAproach, setDebtEstimationAproach] = useState("Actual Rating");
    const [rating, setRating] = useState("Aaaa/AAA");
    const [pre_tax_cost_debt, setpre_tax_cost_debt] = useState(2);
    const [type_of_company, settype_of_company] = useState(2)
    useEffect(()=>{ 
        console.log(footNotesList)
        axios.get(`${URLS.API_URL}/configs/rating/qualitativedata/?limit=1000`)
            .then((res)=>{
                loadQualitativeData(res.data.results);
                // console.log(res.data.results);
        }).catch((error)=>{
            console.log(error)
        });
        axios.get(`${URLS.API_URL}/parameters/countries_params/countries/?limit=1000`)
        .then((res)=>{
            setCountriesList(res.data.results);
            // console.log(res.data.results);
        }).catch((error)=>{
            console.log(error)
        })
        axios.get(`${URLS.API_URL}/parameters/params/activities/?limit=1000`).then((resp)=>{
            // console.log(resp.data.results)
            setActivitiesList(resp.data.results);
        }).catch((error)=>{
            console.log(error);
        });
        axios.get(`${URLS.API_URL}/parameters/industries_params/legal_status/?limit=1000`).then((resp)=>{
            setlegalStatusList(resp.data.results);
            // console.log(resp.data.results);
        }).catch((error)=>{
            console.log(error);
        });
    },[]);

    const fillFormFields=(data)=>{
        console.log(data)
        const currency_ = getCountryCurrency(data.head_office, countriesList);
        setLocalCurrency(currency_)
        setlegalStatus(data.legal_form);
        setFirstYear(data.data_birth);
        setHeadOffice(data.head_office);
        setMainActivity(data.activity);
        setStaff(data?.staff);
        setNumberOfShares(data.number_of_shares);
        const companyUnderControl = data.privately_owned_foreign || data.nationally_owned || data.publicly_owned;
        console.log(companyUnderControl)
        setUndercontrol(companyUnderControl)

        let business_revenues=[]
        let act_codes = []

        // Getting work breakdown infos
        // for(let i=0;i<data?.business_revenues?.length;i++){
        //     for(let j=0;j<activitiesList?.length;j++){
        //         if(String(data?.business_revenues[i].business)===String(activitiesList[j]?.code)){
        //             console.log(`Good ${data?.business_revenues[i].business} ${activitiesList[j]?.code}`)
        //             act_codes.push(data?.business_revenues[i]);
        //             let newbusiness = {"business":activitiesList[j]?.code, "revenue":data?.business_revenues[i]?.revenue}
        //             business_revenues.push(newbusiness)
        //         }
        //     }
        // }

        setbusinessRevenues(data?.business_revenues)
        // git activity amort period
        for(let k=0; k<activitiesList?.length; k++){
            if(mainActivity===String(activitiesList[k].code & activitiesList[k].industry?.amortizing_period)){
                setRdAmortPeriod(activitiesList[k].industry?.amortizing_period);
            }else{
                setRdAmortPeriod(1);
            }
        }
        setAssets(data.balance_sheet.assets);
        setLiabilities(data.balance_sheet.liabilities);
        setIncomeStatement(data.income_statement);
        setCashflow(data.cash_flow);
        setFootNotesList(data.foot_note);
        setCurrency(currency_)
    }

    const setFootNoteValues=(reference, value, position)=>{
        let _footNote = footNotesList;
        console.log(footNotesList)
        for(let i=0;i<_footNote[0].posts?.length;i++){
                if(_footNote[0].posts[i].reference===reference){
                    _footNote[0].posts[i][position]=Number(value);
                }else{
                    if(!_footNote[0].posts[i][position]){
                        _footNote[0].posts[i][position]=0   
                    }
            }
        }
        setFootNotesList(_footNote);
        console.log(footNotesList)
    }

    const getQualitativeData=(variable, value)=>{
        let _qualitativeData = qualitativeData
        for(let i=0;i<_qualitativeData?.length;i++){
            if(_qualitativeData[i].variable===variable){
                _qualitativeData[i].value=value
            }
        }
        loadQualitativeData(_qualitativeData)
        console.log(qualitativeData)
    }

    const extract_data=(event)=>{
        setLoading(true);
        const formData = new FormData();
        formData.append("file1",file1, file1.name)
        formData.append("file2",file2, file2.name);
        API.post(URLS.CRR.extractData(), formData).then((resp)=>{
            fillFormFields(resp.data);
            setLoading(false);
            toast.success("Data successfully extracted",{position: toast.POSITION.TOP_RIGHT, theme:"colored"});
            toast.info("Make sure you verify your data and add any additinnal information that is needed then proceed to obtain your rating",{position: toast.POSITION.BOTTOM_RIGHT, theme:"colored", autoClose: true, timeOut:5000})
            setExtracted(true);
        }).catch((err)=>{

        });
    }

    const onCancel=()=>{
        setFile1(null);
        setFile2(null);
        setExtracted(false);
    }

    const checkErrors=()=>{
        for(let i=0;i<qualitativeData?.length;i++){
            if(!qualitativeData[i].value){
                toast.error("Provide Qualitative Data", {position:toast.POSITION.TOP_CENTER});
                return false;
            }
        }
        if(countryRevenu?.length===0){
            toast.error("Provide Your country revenues", {position:toast.POSITION.TOP_CENTER})
            return false
        }else{
            for(let i=0; i<countryRevenu?.length; i++){
                if(!countryRevenu[i].country || !countryRevenu[i]?.revenue){
                    toast.error("Provide Your country revenues", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                    return false;
                }
            }
        }
        return true;
    }

    const onSubmitRating = () => {
        if(checkErrors()){
            const data = {
                activity: mainActivity,
                currency: currency,
                tax_rate: taxRate,
                vat_rate: vatRate,
                year: annee,
                period: 12,
                rd_amortizing_period: rdAmortPeriod,
                ol_amortizing_period: olAmortPeriod,
                staff: staff,
                legal_form: legalStatus,
                head_office: headOffice,
                creation_year: firstYear,
                market_price: 0,
                number_of_shares: numberOfShares,
                operating_countries: countryRevenu,
                business_revenues: businessRevenues,
                balance_sheet :{
                    assets: assets,
                    liabilities: liabilities
                },
                income_statement: incomeStatement,
                cash_flow: cashflow,
                foot_note: footNotesList,
                qualitative_data: qualitativeData,
                debt_details: {
                    average_maturity_of_debt: average_maturity_of_debt,
                    pre_tax_cost_debt_estimation_approach: debtEstimationAproach,
                    pre_tax_cost_debt: pre_tax_cost_debt,

                    rating: rating,
                    type_of_company: type_of_company
                },
                ol_expenses: olExpenses,
                rd_expenses: rdExpenses
            }
            console.log(data)
            setLoading(true);
            API.post(URLS.CRR.create(),data).then((resp)=>{
                setLoading(false);
                navigage(`/sme-crr/details/${resp.data.id}/${resp.data.reference}`)
            }).catch((error)=>{
                console.log(error);
                toast.error(error?.response?.data??"Error Rating Your enterprise", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                setLoading(false)
            })
        }
    }

    const setRDamort=(period)=>{
        let amort=Array()
        for(let i=0; i<period; i++){
            amort.push({"year":annee-(i+1), "amount":0.0})
        }
        setRdExpenses(amort);
    }

    const saveRDExpenses=(event, index)=>{
        let expenses = rdExpenses;
        expenses[index].amount = Number(event.target.value);
        setRdExpenses(expenses);
        console.log(rdExpenses)
    }

    const getCountryRevenu=(value)=>{
        let revenues=Array();
        for(let i=0; i<value; i++){
            revenues.push({"country":countriesList[i].code,"revenue":0})
        }
        setCountryRevenu(revenues);
    }

    const saveCountryRevenue=(value,pos,index)=>{
        let revenues = countryRevenu;
        if(pos==="country"){
            revenues[index].country=value;
        }
        if(pos==="revenue"){
            revenues[index].revenue=Number(value);
        }
        setCountryRevenu(revenues)
        console.log(countryRevenu)
    }

    const getOlExpenses=(value)=>{
        setOlAmortPeriod(value)
        let expenses = [];
        for(let i=0;i<value;i++){
            expenses.push({"year":annee+i, "amount":0.0})
        }
        setOlExpenses(expenses);
    }

    const saveOLExpenses=(event, index)=>{
        let expenses = olExpenses
        expenses[index].amount=Number(event.target.value);
        setOlExpenses(expenses);
        console.log(olExpenses);
    }
    return <>
            <BreadCrumb title1="CRR-SME" title2="New"  link="/sme-crr"/>
            
            <div className={loading?"col-12 disabled":"col-12"} data-bg-color="#F6F6F9">
                <div className="alert alert-info" role="alert"><strong className="text-capitalize">Info!</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus quos omnis illo nulla nobis beatae labore. Magnam facilis ducimus impedit minus, perspiciatis voluptatum itaque autem harum, velit, numquam facere porro!.
                    <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                {!extracted?(
                    <CRRFormInit t={t} extract_data={extract_data} setFile1={setFile1} setFile2={setFile2} file1={file1} file2={file2}/>
                ):(
                <div className="row mb-2">
                    <div className="col-2">
                        <button className="btn btn-danger" onClick={(e)=>{onCancel()}}>
                            {t("textCancel")}
                        </button>
                    </div>
                    <div className=" col"><div style={{float:"right"}}>
                        <button className="btn m-1" type="button" style={{backgroundColor:"#1D4361", color:"white"}} 
                        onClick={(e)=>{onSubmitRating()}}>{t("textProcedd")}</button></div>
                    </div>
                    <div>
                        <ul className="nav nav-tabs profile-nav mb-4" id="profileTab" role="tablist"style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link active" id="balancesheet-tab" data-toggle="tab" href="#balancesheet" role="tab" aria-controls="balancesheet" aria-selected="false">{t("FIN_STATEMENT.BALANCESHEET")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="incomestatement-tab" data-toggle="tab" href="#incomestatement" role="tab" aria-controls="incomestatement" aria-selected="false">{t("FIN_STATEMENT.INCOME_STATEMENT")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="cashflow-tab" data-toggle="tab" href="#cashflow" role="tab" aria-controls="cashflow" aria-selected="true">{t("FIN_STATEMENT.CASHFLOW")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="footnote-tab" data-toggle="tab" href="#footnote" role="tab" aria-controls="footnote" aria-selected="false">{t("FIN_STATEMENT.FOOT_NOTE")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="qdata-tab" data-toggle="tab" href="#qdata" role="tab" aria-controls="qdata" aria-selected="true">{t("FIN_STATEMENT.QDATA")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="others-tab" data-toggle="tab" href="#others" role="tab" aria-controls="others" aria-selected="false">{t("textOthers")}</a></li>
                        </ul>
                        <div className="tab-content" id="tabContent" style={{marginRight:0, marginLeft:0}}>
                            {/* Display balancsheet */}
                            <div className="tab-pane fade active show" id="balancesheet" role="tabpanel" aria-labelledby="balancesheet-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-12">
                                        <label className="switch pr-5 switch-primary mt-2 mb-4 float-left">
                                            <span style={{color:"black"}}>{t("FIN_STATEMENT.VIEW")}</span>
                                            <input type="checkbox" checked={landScape} onChange={()=>{setLandScape(!landScape)}}/><span className="slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={!landScape?"col-xl-12 mb-5":"col-xl-7 mb-5"}>
                                        <DisplayAsset assets={assets} t={t} landscape={landScape}/>
                                    </div>
                                    <div className={!landScape?"col-xl-12 mb-5":"col-xl-5 mb-5"}>
                                        <DisplayLiability liabilities={liabilities} t={t} landscape={landScape}/>
                                    </div>
                                </div>
                            </div>
                            {/* Display income statement */}
                            <div className="tab-pane fade" id="incomestatement" role="tabpanel" aria-labelledby="incomestatement-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-xl-12 mb-5">
                                        <DisplayIncomeStatement incomeStatement={incomeStatement} t={t}/>
                                    </div>
                                </div>
                            </div>
                            {/* Display cashflow */}
                            <div className="tab-pane fade" id="cashflow" role="tabpanel" aria-labelledby="cashflow-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-xl-12 mb-5">
                                        <DisplayCashFlow cashflow={cashflow} t={t}/>
                                    </div>
                                </div>
                            </div>
                            {/* Display Footnote form and data */}
                            <div className="tab-pane fade" id="footnote" role="tabpanel" aria-labelledby="footnote-tab" style={{background:"#fff"}}>
                                <FootNoteForm footNote={footNotesList} setFootNoteValues={setFootNoteValues} t={t}/>
                            </div>
                            {/* Display qualitative data */}
                            <div className="tab-pane fade" id="qdata" role="tabpanel" aria-labelledby="qdata-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-xl-6 mb-5">
                                        <QualitativeDataForm qualitativeData={qualitativeData} getQualitativeData={getQualitativeData} t={t}/>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="others" role="tabpanel" aria-labelledby="others-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-xl-4 mb-0">
                                        <BusinessRevenueTable businessRevenues={businessRevenues} t={t}/>
                                    </div>
                                    <div className="col-xl-4 mb-0">
                                        <RDExpnesesForm  setRdInputMethod={setRdInputMethod} rdInputMethod={rdInputMethod} 
                                        rdExpenses={rdExpenses} setRDamort={setRDamort} saveRDExpenses={saveRDExpenses} t={t}
                                        amortPreriod={rdAmortPeriod}/>
                                    </div>
                                    <div className="col-xl-4 mb-5">
                                        <CompanyDetailsForm headOffice={headOffice} countriesList={countriesList} legalForm={legalStatus}
                                        legalStatusList={legalStatusList} taxRate={taxRate} setTaxRate={setTaxRate} firstYear={firstYear}
                                        vatRate={vatRate} setVatRate={setVatRate} setVisa={setVisa} underControl={underControl} expertvisa={visa} t={t}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4 mb-5">
                                        <CountryRevenueForm countryRevenu={countryRevenu} countries={countriesList} saveCountryRevenue={saveCountryRevenue} 
                                        getCountryRevenu={getCountryRevenu} t={t}/>
                                    </div>
                                    <div className="col-xl-4 mb-5">
                                        <LeasingForm getOlExpenses={getOlExpenses} olExpenses={olExpenses} annee={annee} saveOLExpenses={saveOLExpenses} t={t}/>
                                    </div>
                                    <div className="col-xl-4 mb-5">
                                        <DebtDetailsForm setDebtEstimationAproach={setDebtEstimationAproach} 
                                        debtEstimationAproach={debtEstimationAproach}
                                        setRating={setRating} settype_of_company={settype_of_company} 
                                        setpre_tax_cost_debt={setpre_tax_cost_debt} 
                                        setaverage_maturity_of_debt={setaverage_maturity_of_debt}
                                        t={t}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>

    </>
}
export default SMECRRForm;