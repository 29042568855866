import { useTranslation } from "react-i18next";
import { curencyFormater } from "../../../components/utils/functions";

const DisplayAssets = ({assets, length, years}) => {
    const {t} = useTranslation()
    length = length*3+1
    console.log(assets)
    return (
            <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white"> Assets</h3>
            </div>
            <div className="table-responsive table-stripe">
                <table className="table-striped table-bordered table-responsive-lg table">
                    <thead className="" style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            <th scope="col">Label</th>
                            {length===16 &&(<>
                                <th colSpan={1} className="text-center">{years[5]}</th>
                                <th colSpan={3} className="text-center">{years[3]}</th>
                                <th colSpan={3} className="text-center">{years[2]}</th>
                                <th colSpan={3} className="text-center">{years[1]}</th>
                                <th colSpan={3} className="text-center">{years[0]}</th>
                            </>)}
                            {length===13 &&(<>
                                <th colSpan={1} className="text-center">{years[4]}</th>
                                <th colSpan={3} className="text-center">{years[3]}</th>
                                <th colSpan={3} className="text-center">{years[2]}</th>
                                <th colSpan={3} className="text-center">{years[1]}</th>
                                <th colSpan={3} className="text-center">{years[0]}</th>
                            </>)}
                            {length===10 &&(<>
                                <th colSpan={1} className="text-center">{years[3]}</th>
                                <th colSpan={3} className="text-center">{years[2]}</th>
                                <th colSpan={3} className="text-center">{years[1]}</th>
                                <th colSpan={3} className="text-center">{years[0]}</th>
                            </>)}
                            {length===7 &&(<>
                                <th colSpan={1} className="text-center">{years[2]}</th>
                                <th colSpan={3} className="text-center">{years[1]}</th>
                                <th colSpan={3} className="text-center">{years[0]}</th>
                            </>)}
                        </tr>
                        <tr>
                            <th scope="col"></th>
                            {length===16 &&(<>
                                <th scope="col" className="text-center">Net</th>
                                <th scope="col"  className="text-center">Gross</th>
                                <th scope="col"  className="text-center">Amort/Deprec</th>
                                <th scope="col"  className="text-center">Net</th>
                                <th scope="col"  className="text-center">Gross</th>
                                <th scope="col"  className="text-center">Amort/Deprec</th>
                                <th scope="col"  className="text-center">Net</th>
                                <th scope="col"  className="text-center">Gross</th>
                                <th scope="col"  className="text-center">Amort/Deprec</th>
                                <th scope="col"  className="text-center">Net</th>
                            </>)}
                            {length===13 &&(<>
                                <th scope="col"  className="text-center">Net</th>
                                <th scope="col"  className="text-center">Gross</th>
                                <th scope="col"  className="text-center">Amort/Deprec</th>
                                <th scope="col"  className="text-center">Net</th>
                                <th scope="col"  className="text-center">Gross</th>
                                <th scope="col"  className="text-center">Amort/Deprec</th>
                                <th scope="col"  className="text-center">Net</th>
                            </>)}
                            {length===10 &&(<>
                                <th scope="col"  className="text-center">Net</th>
                                <th scope="col"  className="text-center">Gross</th>
                                <th scope="col"  className="text-center">Amort/Deprec</th>
                                <th scope="col"  className="text-center">Net</th>
                            </>)}
                            {length===7 &&(<>
                                <th scope="col"  className="text-center">Net</th>
                            </>)}
                            <th scope="col"  className="text-center">Gross</th>
                            <th scope="col"  className="text-center">Amort/Deprec</th>
                            <th scope="col"  className="text-center">Net</th>
                            <th scope="col"  className="text-center">Gross</th>
                            <th scope="col"  className="text-center">Amort/Deprec</th>
                            <th scope="col"  className="text-center">Net</th>
                        </tr>
                    </thead>
                    {assets?.map((value, index)=>(
                        <tbody className="table-striped" key={index}>
                        {value.rubriques.map((rub)=>
                            (<>
                                {rub.posts.map((post)=>(
                                    <tr key={post.reference}>
                                        {/* <td>{post.reference}</td> */}
                                        <td>{t(`ASSETS.${post.reference}`)}</td>
                                        
                                        {/*  <td style={{textAlign:"right"}}>{curencyFormater(post.net3?post.net3:0)}</td> 
                                         <td style={{textAlign:"right"}}>{curencyFormater(post.brut2?post.brut2:0.0)}</td>   
                                         <td style={{textAlign:"right"}}>{curencyFormater(post.amort_deprec2?post.amort_deprec2:0.0)}</td> */}
                                        {length>=16 &&(
                                            <>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.net5?post.net5:0.0)}</td>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.brut4?post.brut4:0.0)}</td>   
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.amort_deprec4?post.amort_deprec4:0.0)}</td>
                                            </>
                                        )}
                                        {length>=13 &&(
                                            <>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.net4?post.net4:0.0)}</td>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.brut3?post.brut3:0.0)}</td>   
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.amort_deprec3?post.amort_deprec3:0.0)}</td>
                                            </>
                                        )}
                                        {length>=10 &&(
                                            <>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.net3?post.net3:0.0)}</td>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.brut2?post.brut2:0.0)}</td>   
                                                 <td style={{textAlign:"right"}}>{curencyFormater(post.amort_deprec2?post.amort_deprec2:0.0)}</td>
                                            </>
                                        )}
                                        {length>=7 &&( <td style={{textAlign:"right"}}>{curencyFormater(post.net2?post.net2:0.0)}</td>)}
                                        <td style={{textAlign:"right"}}>{curencyFormater(post.brut1?post.brut1:0.0)}</td>   
                                        <td style={{textAlign:"right"}}>{curencyFormater(post.amort_deprec1?post.amort_deprec1:0.0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(post.net1?post.net1:0.0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(post.brut?post.brut:0.0)}</td>   
                                        <td style={{textAlign:"right"}}>{curencyFormater(post.amort_deprec?post.amort_deprec:0.0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(post.net?post.net:0.0)}</td>
                                    </tr>
                                ))}
                                <tr key={rub.reference} style={{backgroundColor:rub.posts.length!==0?"var(--tp-common-gray)":"inherit", color:rub.posts.length!==0?"black":"inherit"}}>
                                    {/* <td >{rub.reference}</td> */}
                                        <td>{t(`ASSETS.${rub.reference}`)}</td>
                                        {length>=16 &&(
                                            <>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.net5?rub.net5:0.0)}</td>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.brut4?rub.brut4:0.0)}</td>   
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.amort_deprec4?rub.amort_deprec4:0.0)}</td>
                                            </>
                                        )}
                                        {length>=13 &&(
                                            <>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.net4?rub.net4:0.0)}</td>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.brut3?rub.brut3:0.0)}</td>   
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.amort_deprec3?rub.amort_deprec3:0.0)}</td>
                                            </>
                                        )}
                                        {length>=10 &&(
                                            <>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.net3?rub.net3:0.0)}</td>
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.brut2?rub.brut2:0.0)}</td>   
                                                 <td style={{textAlign:"right"}}>{curencyFormater(rub.amort_deprec2?rub.amort_deprec2:0.0)}</td>
                                            </>
                                        )}
                                        {length>=7 &&( <td style={{textAlign:"right"}}>{curencyFormater(rub.net2?rub.net2:0.0)}</td>)}
                                    
                                     <td style={{textAlign:"right"}}>{curencyFormater(rub.brut1?rub.brut1:0)}</td>
                                     <td style={{textAlign:"right"}}>{curencyFormater(rub.amort_deprec1?rub.amort_deprec1:0)}</td>
                                     <td style={{textAlign:"right"}}>{curencyFormater(rub.net1?rub.net1:0)}</td>

                                     <td style={{textAlign:"right"}}>{curencyFormater(rub.brut?rub.brut:0)}</td>
                                     <td style={{textAlign:"right"}}>{curencyFormater(rub.amort_deprec?rub.amort_deprec:0)}</td>
                                     <td style={{textAlign:"right"}}>{curencyFormater(rub.net?rub.net:0)}</td>
                                </tr>
                            </>)
                        )}
                        <tr key={value.reference} style={{backgroundColor:value.rubriques.length!==0 || value.reference==="BZ"?"#8da9c4":"inherit", color:value.rubriques.length!==0 || value.reference==="BZ"?"white":"inherit", textTransform:value.rubriques.length!==0 || value.reference==="BZ"?"uppercase":"inherit"}}>
                            {/* <td>{value.reference}</td> */} 
                            {/* {length<10 ?(<td >{value.label}</td>):( */}
                                    <td>{t(`ASSETS.${value.reference}`)}</td>
                                {/* )} */}
                                {length>=16 &&(
                                    <>
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.net5?value.net5:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.brut4?value.brut4:0.0)}</td>   
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.amort_deprec4?value.amort_deprec4:0.0)}</td>
                                    </>
                                )}
                                {length>=13 &&(
                                    <>
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.net4?value.net4:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.brut3?value.brut3:0.0)}</td>   
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.amort_deprec3?value.amort_deprec3:0.0)}</td>
                                    </>
                                )}
                                {length>=10 &&(
                                    <>
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.net3?value.net3:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.brut2?value.brut2:0.0)}</td>   
                                            <td style={{textAlign:"right"}}>{curencyFormater(value.amort_deprec2?value.amort_deprec2:0.0)}</td>
                                    </>
                                )}
                                {length>=7 &&( <td style={{textAlign:"right"}}>{curencyFormater(value.net2?value.net2:0.0)}</td>)}
                            
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.brut1?value.brut1:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.amort_deprec1?value.amort_deprec1:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net1?value.net1:0)}</th>

                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.brut?value.brut:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.amort_deprec?value.amort_deprec:0)}</th>
                                <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net?value.net:0)}</th>
                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            </div>
        // </div>
    )
}
const DisplayLiab = ({liabilities, length, years}) => {
    // console.log(liabilities)
    // console.log(length)
    const {t} = useTranslation()
    return (
        // <div className="card text-left">
            <div className="card text-left o-hidden">
                <div className="card-header d-flex align-items-center border-0 my-header">
                    <h3 className="w-100 float-left card-title m-0 text-white"> liabilities</h3>
                </div>
                <div className="table-responsive">
                    <table className="table-striped table-bordered table-responsive-lg table">
                        <thead className="" style={{backgroundColor:"var(--tp-common-gray)"}}>
                            <tr>
                                <th scope="col">Label</th>
                                {length===6 &&(<th scope="col" className="text-center">{years[5]}</th>)}
                                {length===5 &&(<th scope="col" className="text-center">{years[4]}</th>)}
                                {length===4 &&(<th scope="col" className="text-center">{years[3]}</th>)}
                                <th scope="col" className="text-center">{years[2]}</th>
                                <th scope="col" className="text-center">{years[1]}</th>
                                <th scope="col" className="text-center">{years[0]}</th>
                            </tr>
                        </thead>
                        {liabilities?.map((value, index)=>(
                            <tbody className="table-striped" key={index}>
                            {value.rubriques.map((rub)=>
                                (<>
                                    {rub.posts.map((post)=>(
                                        <tr key={post.reference}>
                                            {/* <td>{post.reference}</td> */}
                                            <td >{t(`LIABILITIES.${post.reference}`)}</td>
                                            {length===6 &&( <td style={{textAlign:"right"}}>{curencyFormater(post.net5?post.net5:0)}</td>)}
                                            {length===5 &&( <td style={{textAlign:"right"}}>{curencyFormater(post.net4?post.net4:0)}</td>)}
                                            {length===4 &&( <td style={{textAlign:"right"}}>{curencyFormater(post.net3?post.net3:0)}</td>)}
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net2?post.net2:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net1?post.net1:0.0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net?post.net:0.0)}</td>
                                        </tr>
                                    ))}
                                    {rub.reference==="DQ" && (
                                        <tr style={{backgroundColor:"white", color:"white"}}>
                                            <td colSpan={length+2}>..</td>
                                            {/* <td ></td>
                                            <td ></td>
                                            <td ></td> */}
                                        </tr>
                                    )}
                                    <tr key={rub.reference} style={{backgroundColor:rub.posts.length!==0?"var(--tp-common-gray)":"inherit", color:rub.posts.length!==0?"black":"inherit"}}>
                                        {/* <td >{rub.reference}</td> */}
                                        <td >{t(`LIABILITIES.${rub.reference}`)}</td>
                                        {length===6 &&( <td style={{textAlign:"right"}}>{curencyFormater(rub.net5?rub.net5:0)}</td>)}
                                        {length===5 &&( <td style={{textAlign:"right"}}>{curencyFormater(rub.net4?rub.net4:0)}</td>)}
                                        {length===4 &&( <td style={{textAlign:"right"}}>{curencyFormater(rub.net3?rub.net3:0)}</td>)}
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net2?rub.net2:0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net1?rub.net1:0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net?rub.net:0)}</td>
                                    </tr>
                                </>)
                            )}
                            <tr key={value.reference} style={{textTransform:value.rubriques.length!==0 || value.reference==="DZ"?"uppercase":"inherit",backgroundColor:value.rubriques.length!==0 || value.reference==="DZ"?"#8da9c4":"inherit", color:value.rubriques.length!==0 || value.reference==="DZ"?"white":"inherit"}}>
                                {/* <td>{value.reference}</td> */}
                                <td>{t(`LIABILITIES.${value.reference}`)}</td>
                                {length===6 &&( <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net5?value.net5:0)}</th>)}
                                {length===5 &&( <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net4?value.net4:0)}</th>)}
                                {length===4 &&( <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net3?value.net3:0)}</th>)}
                                    <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net2?value.net2:0)}</th>
                                    <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net1?value.net1:0)}</th>
                                    <th scope="col" style={{textAlign:"right"}}>{curencyFormater(value.net?value.net:0)}</th>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        // </div>
    )
}

const DisplayIncome = ({income, length, years}) => {
    // console.log(length)
    // console.log(income)
    const {t} = useTranslation();
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">Income Statement</h3>
            </div>
            <div className="table-responsive table-stripe">
                <table className="table-striped table-bordered table-responsive-lg table">
                    <thead className="" style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            <th scope="col">Label</th>
                            {length===6 &&(<th scope="col" className="text-center">{years[5]}</th>)}
                            {length===5 &&(<th scope="col" className="text-center">{years[4]}</th>)}
                            {length===4 &&(<th scope="col" className="text-center">{years[3]}</th>)}
                            <th scope="col" className="text-center">{years[2]}</th>
                            <th scope="col" className="text-center">{years[1]}</th>
                            <th scope="col" className="text-center">{years[0]}</th>
                        </tr>
                    </thead>
                    {income.map((value)=>(
                        <tbody key={value.reference}>
                            {value.rubriques.map((rub)=>
                                (<>
                                    {rub.posts.map((post)=>(
                                        <tr key={post.reference}>
                                            {/* <td>{post.reference}</td> */}
                                            <td >{t(`INCOME_STATEMENT.${post.reference}`)}</td>
                                            {length===6 &&(<td style={{textAlign:"right"}}>{curencyFormater(post.net5?post.net5:0)}</td>)}
                                            {length===5 &&(<td style={{textAlign:"right"}}>{curencyFormater(post.net4?post.net4:0)}</td>)}
                                            {length===4 &&(<td style={{textAlign:"right"}}>{curencyFormater(post.net3?post.net3:0)}</td>)}
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net2?post.net2:0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net1?post.net1:0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net?post.net:0)}</td>
                                        </tr>
                                    ))}
                                    {rub.reference==="DQ" && (
                                    <tr style={{backgroundColor:"white", color:"white"}}>
                                        <td ></td>
                                        <td colSpan={length+2}>5000000000000</td>
                                    </tr>

                                    )}
                                    <tr key={rub.reference} style={{backgroundColor:rub.posts.length!==0?"var(--tp-common-gray)":"inherit", color:rub.posts.length!==0?"black":"inherit"}}>
                                        {/* <td >{rub.reference}</td> */}
                                        <td >{t(`INCOME_STATEMENT.${rub.reference}`)}</td>
                                        {length===6 &&(<td style={{textAlign:"right"}}>{curencyFormater(rub.net5?rub.net5:0)}</td>)}
                                        {length===5 &&(<td style={{textAlign:"right"}}>{curencyFormater(rub.net4?rub.net4:0)}</td>)}
                                        {length===4 &&(<td style={{textAlign:"right"}}>{curencyFormater(rub.net3?rub.net3:0)}</td>)}
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net2?rub.net2:0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net1?rub.net1:0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net?rub.net:0)}</td>
                                    </tr>
                                </>)
                            )}
                            <tr key={value.reference} style={{textTransform:value.rubriques.length!==0 || value.reference==="XI"?"uppercase":"inherit",backgroundColor:value.rubriques.length!==0 || value.reference==="XI"?"#8da9c4":"inherit", color:value.rubriques.length!==0 || value.reference==="XI"?"white":"inherit"}}>
                                {/* <td >{value.reference}</td> */}
                                <td >{t(`INCOME_STATEMENT.${value.reference}`)}</td>
                                {length===6 &&(<td style={{textAlign:"right"}}>{curencyFormater(value.net5?value.net5:0)}</td>)}
                                {length===5 &&(<td style={{textAlign:"right"}}>{curencyFormater(value.net4?value.net4:0)}</td>)}
                                {length===4 &&(<td style={{textAlign:"right"}}>{curencyFormater(value.net3?value.net3:0)}</td>)}
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net2?value.net2:0)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net1?value.net1:0)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net?value.net:0)}</td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    )
}

const DisplayCashFlow = ({cashflow, length, years}) => {
    // console.log(length)
    const { t } = useTranslation();
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">Cash Flow</h3>
            </div>
            <div className="table-responsive">
                <table className="table-striped table-bordered table-responsive-lg table">
                    <thead className="" style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            <th scope="col">Label</th>
                            {length===6 &&(<th scope="col" className="text-center">{years[5]}</th>)}
                            {length===5 &&(<th scope="col" className="text-center">{years[4]}</th>)}
                            {length===4 &&(<th scope="col" className="text-center">{years[3]}</th>)}
                            <th scope="col" className="text-center">{years[2]}</th>
                            <th scope="col" className="text-center">{years[1]}</th>
                            <th scope="col" className="text-center">{years[0]}</th>
                        </tr>
                    </thead>
                    {cashflow.map((value)=>(
                        <tbody key={value.reference}>
                                    {value.rubriques.length!==0 && (
                                    <tr style={{backgroundColor:"var(--tp-common-gray)", textAlign:"center"}}>
                                        <td colSpan={length+2}>{t(`CASH_FLOW.${value.reference}`)}</td>
                                    </tr>
                                    )}
                            {value.rubriques.map((rub)=>
                                (<>
                                    {rub.posts.map((post)=>(
                                        <tr key={post.reference}>
                                            {/* <td>{post.reference}</td> */}
                                            <td >{t(`CASH_FLOW.${post.reference}`)}</td>
                                            {length===6 &&(<td style={{textAlign:"right"}}>{curencyFormater(post.net5?post.net5:0.0)}</td>)}
                                            {length===5 &&(<td style={{textAlign:"right"}}>{curencyFormater(post.net4?post.net4:0.0)}</td>)}
                                            {length===4 &&(<td style={{textAlign:"right"}}>{curencyFormater(post.net3?post.net3:0.0)}</td>)}
                                            <td  style={{textAlign:"right"}}>{curencyFormater(post.net2?post.net2:0.0)}</td>
                                            <td  style={{textAlign:"right"}}>{curencyFormater(post.net1?post.net1:0.0)}</td>
                                            <td  style={{textAlign:"right"}}>{curencyFormater(post.net?post.net:0.0)}</td>
                                        </tr>
                                    ))}
                                    {rub.reference==="DQ" && (
                                    <tr style={{backgroundColor:"white", color:"white"}}>
                                        <td ></td>
                                        <td colSpan={length+2}>5000000000000</td>
                                    </tr>

                                    )}
                                    <tr key={rub.reference} style={{backgroundColor:rub.posts.length!==0?"var(--tp-common-gray)":"inherit", color:rub.posts.length!==0?"black":"inherit"}}>
                                        {/* <td >{rub.reference}</td> */}
                                        <td >{t(`CASH_FLOW.${rub.reference}`)}</td>
                                        {length===6 &&(<td style={{textAlign:"right"}} >{curencyFormater(rub.net5?rub.net5:0)}</td>)}
                                        {length===5 &&(<td style={{textAlign:"right"}} >{curencyFormater(rub.net4?rub.net4:0)}</td>)}
                                        {length===4 &&(<td style={{textAlign:"right"}} >{curencyFormater(rub.net3?rub.net3:0)}</td>)}
                                        <td style={{textAlign:"right"}} >{curencyFormater(rub.net2?rub.net2:0)}</td>
                                        <td style={{textAlign:"right"}} >{curencyFormater(rub.net1?rub.net1:0)}</td>
                                        <td style={{textAlign:"right"}} >{curencyFormater(rub.net?rub.net:0)}</td>
                                    </tr>
                                </>)
                            )}
                            <tr key={value.reference} style={{textTransform:value.rubriques.length!==0 || value.reference==="ZH" || value.reference==="ZA"?"uppercase":"inherit",backgroundColor:value.rubriques.length!==0 || value.reference==="ZH"|| value.reference==="ZA"?"#8da9c4":"inherit", color:value.rubriques.length!==0 || value.reference==="ZH"|| value.reference==="ZA"?"white":"inherit"}}>
                                {/* <td >{value.reference}</td> */}
                                <td >{t(`CASH_FLOW.${value.reference}`)}</td>
                                {length===6 &&(<td style={{textAlign:"right"}}>{curencyFormater(value.net5?value.net5:0.0)}</td>)}
                                {length===5 &&(<td style={{textAlign:"right"}}>{curencyFormater(value.net4?value.net4:0.0)}</td>)}
                                {length===4 &&(<td style={{textAlign:"right"}}>{curencyFormater(value.net3?value.net3:0.0)}</td>)}
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net2?value.net2:0.0)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net1?value.net1:0.0)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net?value.net:0.0)}</td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    )
}
const DisplayFStatement = ({assets, liabilities, income, cashflow, length, years}) => {
    
    return (
        <>
            {/* <div className="col-12 text-center"><h3>Financial statement data</h3></div> */}
            {/* <div className="col-12">
                <div className="alert alert-info" role="alert"><strong className="text-capitalize">Info!</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus quos omnis illo nulla nobis beatae labore. Magnam facilis ducimus impedit minus, perspiciatis voluptatum itaque autem harum, velit, numquam facere porro!.
                    <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
            </div> */}
            <div className="col-12">
                <ul className="nav nav-tabs profile-nav mb-4" id="profileTab" role="tablist"style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                    <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link active" id="balancesheet-tab" data-toggle="tab" href="#balancesheet" role="tab" aria-controls="balancesheet" aria-selected="false">Bilan</a></li>
                    <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="incomestatement-tab" data-toggle="tab" href="#incomestatement" role="tab" aria-controls="incomestatement" aria-selected="false">Compte de resultat</a></li>
                    <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="cashflow-tab" data-toggle="tab" href="#cashflow" role="tab" aria-controls="cashflow" aria-selected="true">Cashflow</a></li>
                </ul>
                <div className="tab-content" id="tabContent" style={{marginRight:0, marginLeft:0}}>
                    <div className="tab-pane fade active show" id="balancesheet" role="tabpanel" aria-labelledby="balancesheet-tab" style={{background:"#fff"}}>
                        <div className="row">
                            <div className="col-12 mb-5">
                                <DisplayAssets assets={assets} length={length} years={years}/>
                            </div>
                            <div className="col-12 mb-5">
                                <DisplayLiab liabilities={liabilities} length={length} years={years}/>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="incomestatement" role="tabpanel" aria-labelledby="incomestatement-tab" style={{background:"#fff"}}>
                        <div className="row">
                            <div className="col-12 mb-5">
                                {/* <h1>The income statement</h1> */}
                                <DisplayIncome income={income} length={length} years={years}/>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="cashflow" role="tabpanel" aria-labelledby="cashflow-tab" style={{background:"#fff"}}>
                        <div className="row">
                            <div className="col-12 mb-5">
                                {/* <h1>The cashflow</h1> */}
                                <DisplayCashFlow cashflow={cashflow} length={length} years={years}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DisplayFStatement;