import { useEffect, useState } from "react";
import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";
const CompanyDetailsForm = ({headOffice, countriesList, legalStatusList, legalForm, taxRate, setTaxRate, 
    vatRate, setVatRate, firstYear,underControl,expertvisa, setVisa, t}) => {
        // console.log(headOffice)
        // console.log(legalStatusList)
        const [legalFormName, setLegalFormName] = useState(null);
        const [headOfficeName, setHeadOfficeName] = useState(null);
        useEffect(()=>{
            for(let i=0; i<countriesList.length; i++){
                if (countriesList[i].code===headOffice){
                    setHeadOfficeName(countriesList[i].code2);
                }
            }

            for(let i=0; i<legalStatusList?.length; i++){
                // console.log(legalStatusList[i])
                if (legalStatusList[i].code===legalForm){
                    setLegalFormName(legalStatusList[i].sigle);
                }
            }
        },[])
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.COMPANYDETAILS")}</h3>
            </div>
            <div className="table-responsive table-strip">
                    <table className="table table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td colSpan={2} >{t("OTHERCRRINFO.HEADQ")}</td> 
                                <td style={{alignItems:"right"}}>{t(`COUNTRY.${headOfficeName}`)}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.LEGALFORM")}</td>
                                <td style={{alignItems:"right"}}>{t(`LEGALFORM.${legalFormName}`)}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.TAX")}</td>
                                <td style={{padding:0, marginTop:0}}>
                                    <input type="number" placeholder={taxRate} style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} onChange={(e)=>{setTaxRate(e.target.value)}}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.VTA")}</td>
                                <td style={{padding:0, marginTop:0}}>
                                    <input type="number" placeholder={vatRate} style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} onChange={(e)=>{setVatRate(e.target.value)}}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.VISA")}</td>
                                <td style={{padding:0, marginTop:0}}>
                                    <Select className="basic-single" 
                                        styles={inLineSelectStyles} 
                                        menuPortalTarget={document.body}
                                        value={expertvisa}
                                        onChange={(e)=>{setVisa(e)}}
                                        options={[
                                            {label:t("textYes"), value:true}, {label:t("textNo"), value:false}]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.UNDERCONTROLE")}</td>
                                <td>
                                    {underControl?t("textYes"):t("textNo")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.YEARCREATE")}</td>
                                <td>
                                    <span style={{width:"inherit", textAlign:"right"}}>{firstYear}</span></td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    )
}
export default CompanyDetailsForm;