import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Children, useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper"; 
import { PiIdentificationCardLight, PiCertificateLight } from "react-icons/pi";
import useSWR from "swr";
import axios from "axios";
import { toast, ToastContainer} from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { styles } from "../../../components/utils/reactSelect"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import Select from "react-select";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import LanguageSwitcher from "../../../components/language";
import LoaderPage from "../../../components/loader";
import { useTranslation } from "react-i18next";
import ChooseCompanyType from "./components/companyChoice";
import AccountDetails from "./components/companyDetails";
import { CHECK_MARK_SVG } from "../../../components/utils/consts";
import { size } from "lodash";
// import { validatePhoneNumber } from "../../../components/utils/functions";
const accountType = [
  {id:1, label:"Enterprise", position:"enterprise"},
  {id:2, label:"Personnal", position:"analyst"}
]
const cityFetcher = (url) => API.get(url).then((res)=>{return res.data.results});
const countryFetcher = (url) => API.get(url).then((res) =>{return res.data.results}); 

const SignupPage = () =>{
    const [step,setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [created, setCreated] = useState(false);
    // let [searchParams, setSearchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);
    const {data: countries, loadingCountries} = useSWR(URLS.PARAMS.COUNTRY.ohada(20),countryFetcher);
    const {data: cyties, loadingCyties} = useSWR(URLS.PARAMS.COUNTRY.CYTIES.ohada(800),cityFetcher);
    const [userCities, setUserCities] = useState([]);
    // const {data: banks, loadingBanks} = useSWR(URLS.USERS.ENTERPRISE.listAll(300),cpnyFetcher)
    const [accountType, setAccountType] = useState(null);
    const [noAccountType, setnoAccountType] = useState(false);
    const [accountSubType, setSubAccountType] = useState(null);
    const [noSubAccountType, setnoSubAccountType] = useState(false);

    const {t} = useTranslation()
    const [firstName, setFirstName] = useState(null);
    const [validFirstName, setValidFirstName] = useState(true);

    const [lastName, setLastName] = useState("");

    const [profession, setProfession] = useState(null);
    const [validProfession, setValidProfession] = useState(true);

    const [email, setEmail] = useState(null);
    const [emailValid, setEmailValid] = useState(true);

    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);

    const [password2, setPassword2] = useState();
    const [equalPassword, setEqualPassword] = useState(true);

    const [phoneNumber, setPhoneNumber] = useState();
    const [phoneValid, setPhoneValid] = useState(true);

    const [agree, setAgree] = useState(false);
    const [agreed, setAgreed] = useState(true);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [validCountry, setValidCountry] = useState(true);

    const [selectedCity, setSelectedCity] = useState("");
    const [validCity, setValidCity] = useState(true);

    const [companyName, setCompanyName] = useState("");

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [validAccount, setValidAccount] = useState(true);

    const validatePassword = (password) => {
        const hasUpperCase  =  /[A-Z]+/.test(password);
        const hasLowerCase  =  /[a-z]+/.test(password);
        const hasNumeric  =  /[0-9]+/.test(password);
        return password && hasLowerCase && hasUpperCase && hasNumeric;
    }

    const validateEmail=(email)=>{
      const domain = email.split("@")[1];
      return email && domain !== "yahoo.com" && domain !== "gmail.com" && domain !== "hotmail.com" && domain !== "yahoo.fr" && domain !== "msn.com"
    }

    const validatePhoneNumber = (phone) =>{
      const hasUpperCase  =  /[A-Z]+/.test(phone);
      const hasLowerCase  =  /[a-z]+/.test(phone);
      const hasNumeric  =  /[0-9]+/.test(phone);
      return phone  && phone.length>8 && !hasUpperCase && !hasLowerCase && hasNumeric;
    }

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };
  const setCountry = (country) => {
    setSelectedCountry(country);
    let cities_ = [];
    cyties?.forEach((element)=>{
      if(element.country===country.value){
        cities_.push(element);
      }
    });
    setUserCities(cities_);
  }
    const onSubmit = () => {
      if(step===0){
        if(!accountType){
          setnoAccountType(true);
        }else{
          if(selectedCountry){
            setStep(1);
          }else{
              setValidCountry(false);
          }}
      }else{
        saveUser();
      }
    }

    const saveUser = () => {
      // console.log(accountType)
      setValidAccount(true);
      setValidFirstName(true);
      setValidAccount(true);
      setValidCountry(true);
      setValidProfession(true);
      setnoSubAccountType(false);
      setEmailValid(true);
      setPhoneValid(true);
      setPasswordValid(true);
      setEqualPassword(true);
      setAgreed(true)
      if(!firstName){
        setValidFirstName(false)
        toast.error("Provide Your first Name")
        return;
      }
      if(!accountType){
        setValidAccount(false);
        toast.error("Select Your Account Type")
        return;
      }
      if((accountType==="financial_inst" || accountSubType==="enterprise") && !accountSubType){
        setnoSubAccountType(true)
      }
      if(!profession){
        setValidProfession(false);
        toast.error("Please Indicate Your profession")
        return;
      }
      if(!email || !validateEmail(email)){
        setEmailValid()
        toast.error("Provide a valid Professionnal Email Address ",);
        setEmailValid(false);
        return;
      }
      if(!selectedCountry){
        setValidCountry(false);
        toast.error("Select your Country");
        return;
      }
      if(!validatePhoneNumber(phoneNumber)){
        setPhoneValid(false)
        toast.error("Provide a valid Phone number",);
        return;
      }
      if(!validatePassword(password)){
        setPasswordValid(false)
        toast.error("Invalid password");
        return;
      }
      if(password!==password2){
        toast.error("Passowrd Not equal")
        setEqualPassword(false)
        return;
      }
      if(!agree){
        toast.error("Agree With our terms before you proceed");
        setAgreed(false);
        return;
      }
      // console.log("Submitting...")
      console.log(selectedCountry)
      setLoading(true);
        const infos = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: `+${selectedCountry.phone}${phoneNumber}`,
        password: password,
        profession: profession,
        password2: password2,
        role: accountType,
        country: selectedCountry.value,
        // country: selectedCountry.url.split("/")[selectedCountry.url.split("/").length-2],
        // enterprise: selectedCompany.value,
        city: selectedCity.value,
        sub_category: accountSubType?.value??"",
        is_superuser: false,
      };
      // console.log(infos)
      API.post(URLS.AUTHS.REGISTRATION, infos).then((resp) => {
        toast.success(resp.data.detail)
        setCreated(true)
        setLoading(false)
      }).catch((error) => {
        // console.log(error)
        setLoading(false)
        if(error?.response?.data?.detail?.includes("password")){
          setPasswordValid(false)
        }
        toast.error(error?.response?.data?.detail);
      })
    };
    // const steps = [{label: "Account Type", onClick: ()=>setStep(0), Children:{[<span className="d-flex"><PiCertificateLight style={{ margin: "auto" }} /></span>]}}, {label: "Account Details", onClick: ()=>setStep(1)}];
    return (
      <>
      {(loadingCountries || loadingCyties) || (cyties===undefined)?(
        <LoaderPage/>
      ):(
        <section className={loading?"tp-faq-area p-relative pt-120 pb-120 disabled":"tp-faq-area p-relative pt-90 pb-50"} 
        style={{
          background:'url("/assets/img/faq/bg-shape.png")', 
        // minHeight:"110vh",
         backgroundPosition:"center", backgroundSize:"cover", backgroundRepeat:"no-repeat", bottom:0, top:0, margin:0, minHeight:"100vh", height:"150%"}}>
             <div className="tp-faq-bg" ></div>
             <div className="container">
                <div className="row">
                   <div className="col-lg-7"style={{display: "flex",alignItems: "center",justifyContent: "center"}}>
                    {!created?(
                      <>
                      <div className="tp-faq-tab-content tp-accordion wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s" style={{minHheight:"100vh", paddingTop:0}}>
                        <Stepper activeStep={step} colSpan={10} stepClassName="stepper-step" connectorStateColors={true} connectorStyleConfig={{completedColor:"#ffff", size:0,activeColor:"#004D6E"}} styleConfig={{size:50, activeBgColor:"#8DA9C4", completedBgColor:"#004D6E"}}>
                          <Step label={step===0?<strong>{t("textAccType")}</strong>:""} onClick={()=>setStep(0)} children={<span className="d-flex"><PiCertificateLight style={{ margin: "auto" }} /></span>} active={step===0?true:false} index={0}/>
                          <Step label={step===1?<strong>{t("textAccDet")}</strong>:""} onClick={()=>setStep(1)} children={<span className="d-flex"><PiIdentificationCardLight style={{ margin: "auto" }} /></span>} active={step===1?true:false} index={1}/>
                        </Stepper>
                        <div className="accordion" id="general_accordion">
                        {step===0 && (
                        <ChooseCompanyType 
                          countries={countries} 
                          country={selectedCountry} 
                          setCountry={setCountry} 
                          validCountry={validCountry} 
                          loading={loading} t={t} 
                          accountType={accountType} 
                          setAccountType={setAccountType}
                          noAccountType={noAccountType}/>
                        )}
                        {step===1 && (
                          <AccountDetails cyties={userCities.length?userCities:cyties} 
                          accountSubType={accountSubType}
                          setSubAccountType={setSubAccountType}
                          accountType={accountType}
                          phoneValid={phoneValid} setPhoneNumber={setPhoneNumber} 
                          loading={loading} validCity={validCity} selectedCity={selectedCity} 
                          setSelectedCity={setSelectedCity} validFirstName={validFirstName} 
                          firstName={firstName} setFirstName={setFirstName}
                          lastName={lastName} setLastName={setLastName} validProfession={validProfession}
                          setProfession={setProfession} profession={profession} emailValid={emailValid} 
                          setEmail={setEmail} agreed={agreed} setAgree={setAgree} passwordValid={passwordValid}
                          setPassword={setPassword}
                          noSubAccountType={noSubAccountType} 
                          companyName={companyName} 
                          setCompanyName={setCompanyName}
                          equalPassword={equalPassword} setPassword2={setPassword2} t={t}/>
                        )}
                            <div className="row row-xs">
                              <div className="col-12">
                              <button className="btn btn-primary btn-block mt-2" disabled={loading} style={{minHeight:"50px"}} onClick={(e)=>{onSubmit()}}>
                                {(loading && step===1) && (
                                    <div className="ladda-button basic-ladda-button"></div>
                                )}
                                {(!loading && step===1) && t("textGetStarted")}
                                {(!loading && step===0) && t("textNext")}</button>
                              </div>
                            </div> 

                            <div className="row row-xs mt-3"style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                              <div className="col-md-6">
                                <span>{t("textHaveAccount")}?</span>
                              </div>
                              <div className="col-md-6 text-right">
                                <Link to="/account/login" style={{fontWeight:"bold", textDecoration:"underline"}} className="text-primary">{t("textLogIn")}</Link>
                              </div>
                            </div>

                            <div className="row row-xs mt-3 mb-3 text-center">
                              <label htmlFor="firstName1 ">{t("textStarParams")} (<span className="text-danger">*</span>) {t("textMandatory")}</label>
                            </div>
                            {/* <div className="mt-3" style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width:"inherit"}}>
                                <LanguageSwitcher/>
                            </div> */}
                          </div>
                      </div>
                      </>):(
                        <div className="tp-faq-tab-content tp-accordion wow fadeInRight" style={{paddingTop:0}}>
                          <div className="row">
                            <div className="col-2" style={{display: "flex",alignItems: "center",justifyContent: "center", paddingTop:0}}>
                              <div className="my-box"><img src={CHECK_MARK_SVG} className="my-img" alt="enterprise"/></div>
                            </div>
                            <div className="col-10 text-center">
                              <p className="mt-4">thanks for joining <strong>bk Research and analytics</strong>, Click on the link received by email to verify your account.</p>
                              
                              <div className="content-centered mt-2">
                                <p><Link to={"/account/login"}><strong>{t("textLogIn")}</strong></Link> </p>
                              </div>
                            </div>
                          </div>
                          </div>
                      )}
                   </div>
                   <div className="col-lg-5">
                      <div className="tp-faq-wrapper" style={{paddingTop:"10px", padding:"5"}}>
                         <div className="tp-faq-title-wrapper">
                            <span className="tp-section-title-pre">
                              <img
                                    alt="B.K. Research"
                                    src="/assets/img/logo/logo_small.png"
                                    className="app-logo"
                                />
                            </span>
                            <h3 className="tp-section-title" dangerouslySetInnerHTML={{ __html: t("textContactQ") }}></h3>
                            <p dangerouslySetInnerHTML={{ __html: t("textContactQDesc") }}></p>
                         </div>
                         <div className="tp-faq-counter-wrapper d-flex">
                            <div className="tp-faq-counter d-flex align-items-center mr-20 mb-30">
                               <div className="tp-faq-counter-icon">
                                  <span>
                                     <img src="/assets/img/faq/icon-1.svg" alt=""/>
                                  </span>
                               </div>
                               <div className="tp-faq-counter-content">
                                  <h4 className="tp-faq-counter-title"><span className="purecounter" data-purecounter-duration="3" data-purecounter-end="10"></span>+2</h4>
                                  <p>{t("textExperience")}</p>
                               </div>
                            </div>
                            <div className="tp-faq-counter d-flex align-items-center mb-30">
                               <div className="tp-faq-counter-icon">
                                  <span>
                                     <img src="/assets/img/faq/icon-2.svg" alt=""/>
                                  </span>
                               </div>
                               <div className="tp-faq-counter-content">
                                  <h4 className="tp-faq-counter-title"><span className="purecounter" data-purecounter-duration="2" data-purecounter-end="2"></span>+10</h4>
                                  <p>{t("textHappyClient")}</p>
                               </div>
                            </div>
                         </div>

                          <div className="mt-3" style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width:"inherit"}}>
                              <LanguageSwitcher/>
                          </div>
                      </div>
                   </div>
                </div>
             </div>
        </section>
        )}
      </>
        
    )
}
export default SignupPage;