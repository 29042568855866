import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import axios from "axios";
import Select from "react-select";
import BreadCrumb from "../../components/breadcrumb"
import { inLineSelectStyles, inLineSelectStyles2, styles } from "../../components/utils/reactSelect";
import SMEFFFormInit from "./components/form-init";
import { toast } from "react-toastify";
import FormFileUpload from "./components/file-uplaod";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import { getMasse, listToMasses3 } from "../../components/utils/functions";
import DisplayFStatement from "./components/raw_statement_display";
import { API, FFAPI } from "../../services/axios";
import ForcastingData from "./components/forcasting_data";
import { MockData } from "../../mock-data/data";
import AsumptionTable from "./components/asumptions-pane";

const SMEFForcastingForm = () =>{
    const annee=(new Date().getFullYear());
    const [forcastingPlug, setForcastingPlug] = useState(null);
    const [formStep, setFormStep] = useState(0)
    const [firstForcastingYear, setFirstForcastingYear] = useState(annee-1)
    const [dataDates, setDataDates] = useState([]);
    const [numDSF, setNumDSF] = useState(0) // number of DSF
    const [stockPrice, setStockPrice] = useState(0);
    const [shares, setShares] = useState(0); // Number of shares
    const [transactionId,setTransactionId] = useState(null);
    const [masses, setMasses] = useState([])
    const [loading,setLoading] = useState(false);
    const [extracted, setExtracted] = useState(null);
    const [isExtracted, setIsextracted] = useState(false);
    const [assetsData, setAssetsData] = useState([]);
    const [liabilitiesData, setLiabilitiesData] = useState([]);
    const [incomeStatementData, setIncomeStatementData] = useState([])
    const [cashflowData, setCashflowData] = useState([]);
    const [asumptionsList, setAsumptionList] = useState([]);
    const [asumptions, setAsumption] = useState(null);
    const [defaultAsumptions, setDefaultAsumption] = useState(null);
    const [restructuredData, setRestructuredData] = useState(null);
    const [forcastedData, setForcastedData] = useState(2); 
    const [horizon, setHorizon] = useState(2);
    const [dataLength, setDataLength] = useState(2);
    const [forcasted, setForcasted] = useState(false);
    const [initialValuationRatio, setInitialValuationRatio] = useState(null);
    const [finalValuationRatio, setFinalValuationRatio] = useState(null);
    const [commonSizeStatement, setCommonSizeStatement] = useState(null);
    const [otherData, setOtherData] = useState(null);
    const [activity, setActivity] = useState(null);
    const [country, setCountry] = useState(null);
    const navigate = useNavigate();
    const cancel = () =>{
        setNumDSF(2);
        setFirstForcastingYear(new Date().getFullYear());
        setFormStep(0)
    }
    
    const setExtractedData = async (data)=>{
        console.log(data)
        setExtracted(data);
        setDataLength(data['income_statement'][0].length-1)
        // console.log(data?.balance_sheet.assets);
        setAssetsData(data?.balance_sheet.assets);
        setLiabilitiesData(data?.balance_sheet.liabilities);
        setCashflowData(data?.cash_flow);
        setIncomeStatementData(data?.income_statement);
        setOtherData(data?.other_data);
        setActivity(data?.activity);
        setCountry(data?.country);
        let list_dates = [String(data?.years?.net).split("-")[0], String(data?.years?.net1).split("-")[0]]
        if (data?.years?.net2){list_dates.push(String(data?.years?.net2).split("-")[0])}
        if (data?.years?.net3){list_dates.push(String(data?.years?.net3).split("-")[0])}
        if (data?.years?.net4){list_dates.push(String(data?.years?.net4).split("-")[0])}
        if (data?.years?.net5){list_dates.push(String(data?.years?.net5).split("-")[0])}
        setDataDates(list_dates);
        setFirstForcastingYear(list_dates[0]);
    }

    const makeForcasting=()=>{
        if(!forcastingPlug){
            toast.error("Please chose a plug", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            return
        }
        setLoading(true);
        const data = {
            'plug': forcastingPlug,
            'num_dsf': numDSF,
            'horizon': horizon,
            'country': country,
            'activity': activity,
            'input':{
                'balance_sheet': extracted.balance_sheet,
                'cash_flow': extracted.cash_flow,
                'income_statement': extracted.income_statement,
                'years': extracted.years,
                'other_data': extracted.other_data
            },
            "parameters":asumptions
        }
        console.log(data)
        FFAPI.post(URLS.FF.simulate(),data)
        .then((resp)=>{
            toast.success("Operation successfuly completed, Check your result", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            setForcastedData(resp.data);
            setFinalValuationRatio(resp.data.valuation_ratios)
            setLoading(false);
            setForcasted(true);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
            toast.error("Unable do perform operation. Wait a moment and reload again", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
        })
    }

    const getUnbalancedState = async () => {
        setLoading(true);

        const data = {
            'plug': null,
            'num_dsf': horizon,
            'horizon': horizon,
            'activity': activity,
            'country': country,
            'input':{
                'balance_sheet': extracted.balance_sheet,
                'cash_flow': extracted.cash_flow,
                'income_statement': extracted.income_statement,
                'years': extracted.years,
                'other_data': extracted.other_data
            },
            "parameters":asumptions
        }
        console.log(data)
        await FFAPI.post(URLS.FF.getUnbalanced(),data)
        .then((resp)=>{
            // console.log(resp.data.unbalanced_forcast)
            setRestructuredData(resp.data.cleaned_data);
            setForcastedData(resp.data.forecast);
            setAsumption(resp.data.forecast_parameters);
            setDefaultAsumption(resp.data.forecast_parameters);
            setCommonSizeStatement(resp.data.common_size_statement);
            setLoading(false)
            toast.success("Your Forasting was properly initiated", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            toast.warning("Make sure you provide the proper plugs assumption parameters and sumilate", {position:toast.POSITION.BOTTOM_RIGHT, theme:"colored", autoClose: false})
        }).catch((error)=>{
            console.log(error)
            setLoading(false)
            toast.error("Make sure your data is consistent and reload again", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
        })
        // console.log(restructuredData)
    }

    const saveData = () => {
        setLoading(true);
        const data = {
            'plug': forcastingPlug,
            'num_dsf': horizon,
            'horizon': horizon,
            'country': country,
            'activity': activity,
            'input':{
                'balance_sheet': extracted.balance_sheet,
                'cash_flow': extracted.cash_flow,
                'income_statement': extracted.income_statement,
                'years': extracted.years,
                'other_data': extracted.other_data
            },
            "parameters":asumptions
        }

        console.log(data)
        FFAPI.post(URLS.FF.save(),data)
        .then((resp)=>{
            console.log(resp.data);
            toast.success("Forcasting Successfully saved", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            navigate(`/f-forcasting/details/${resp.data.id}/${resp.data.id}`)
            setLoading(false);
        }).catch((err)=>{
            console.log(err)
            toast.error("Error saving your data, try in a moment", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            setLoading(false);
        })
    }

    const setParameterValue = (parameter, value) => {
        // changes the value of a given parameter
        let assumptions_ = asumptions;
        assumptions_[parameter] = value;
        setAsumption(assumptions_);
        console.log(assumptions_);
        setForcasted(false);
    }

    const changePlugType = (plug) =>{
        setAsumption(defaultAsumptions);
        setForcastingPlug(plug)
        setForcasted(false);
    }
    useEffect(()=>{
    },[])
    return (
        <>
            <BreadCrumb title1="FF-SME" title2="New"/>
            {(loading)?(
                <LoaderPage/>
            ):(
            <div className={"row"}>
                {!isExtracted?(
                    <SMEFFFormInit annee={annee} numDSF={numDSF} year={firstForcastingYear} setNumDSF={setNumDSF} 
                    setStep={setFormStep} setYear={setFirstForcastingYear} setTransactionId={setTransactionId} 
                    setLoading={setLoading} setExtractedData={setExtractedData}
                    horizon={horizon} setHorizon={setHorizon} setIsextracted = {setIsextracted}
                    
                    forcastingPlug={forcastingPlug} setForcastingPlug={setForcastingPlug}/>
                ):(
                    <>
                        {asumptions==null?(
                            <>
                                <div className="col-12 mt-3 mb-4"><button className="btn btn-primary float-right" onClick={(e)=>getUnbalancedState()}>Proceed</button></div>
                                <DisplayFStatement assets={assetsData} liabilities={liabilitiesData} income={incomeStatementData} cashflow={cashflowData} length={numDSF} years = {dataDates}/>
                            </>
                            ):
                            (<>
                                    <div className="col-12 mb-4">
                                        {forcasted===true &&(
                                            <button className="btn btn-success float-right" onClick={(e)=>{saveData()}} style={{marginLeft:15}}>Save</button>
                                        )}
                                        <button className="btn btn-primary float-right" onClick={(e)=>{makeForcasting()}}>Simulates</button>
                                    </div>
                                <AsumptionTable assumptions={asumptionsList} year1={firstForcastingYear}
                                forcastingPlug={forcastingPlug} setForcastingPlug={changePlugType} setParameter={setParameterValue} horizon={horizon}/>
                                <ForcastingData forecast={forcastedData} restructured={restructuredData} year={firstForcastingYear} number={horizon}/>
                            </>)
                        }
                    </>
                )} 
            </div>
            )}
        </>
    )
}

export default SMEFForcastingForm;