import ParamsWithOptions from "./ParametersWithOptions";

const STDLTDAssumptionsPage = ({parameters, setParameter, plug}) => {
    console.log(plug)
    return <div className="row mb-4">
        <div className="mb-4 col-xl-4">
            <ParamsWithOptions parameters={parameters} setParameter={setParameter} plug={plug}/>
        </div>
        <div className="col">
            {String(plug).includes("LTD_EQ")?
                <div>
                    <h3 className="text-center mb-2 mt-2">Short term Debt and Long term debt and equity</h3>
                </div>:
                <div>
                    <h3 className="text-center mb-2 mt-2">Short term debt long term debt</h3>
                </div>
            }
            <div className="row">
                <div className="mb-4 col-4 float-left">
                    <label>Short term rate <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('shortTermRate',parseFloat(e.target.value))}} value={parameters['shortTermRate']}/>
                </div>
                <div className="mb-4 col-4 float-left">
                    <label>Short term rate <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('shortTermRate',parseFloat(e.target.value))}} value={parameters['shortTermRate']}/>
                </div>
                <div className="mb-4 col-4 float-right">
                    <label>Long term Interest <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('longTermInt',parseFloat(e.target.value))}} value={parameters['longTermInt']}/>
                </div>
                <div className="mb-4 col-4 float-left">
                    <label>Proportion of Short term debt <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('propSTD',parseFloat(e.target.value))}} value={parameters['propSTD']}/>
                </div>
                <div className="mb-4 col-4 float-left">
                    <label>Proportion of Long term debt <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('propLTD',parseFloat(e.target.value))}} value={parameters['propLTD']}/>
                </div>
                {String(plug).includes("LTD_EQ")&&
                <div className="mb-4 col-4  float-left">
                    <label>Proportion of Equity <span className="ml-2 text-danger text-14">*</span></label>
                    <input type="number" onChange={(e)=>{setParameter('propEQ',parseFloat(e.target.value))}} value={parameters['propEQ']}/>
                </div>}
            </div>
        </div>
    </div>

}
export default STDLTDAssumptionsPage;