import BreadCrumb from "../../components/breadcrumb"
import useSWR from "swr";
import { API, FFAPI } from "../../services/axios";
import { URLS } from "../../services/urls";
import { Link } from "react-router-dom";
import { MockData } from "../../mock-data/data";
import LoaderPage from "../../components/loader";
import NoTransaction from "../../components/no-transaction/emptypage";


const fetcher = (url) => FFAPI.get(url).then((res) => { console.log(res.data.results); return res.data.results});
const HomeSMEFForcasting = () =>{
    const {data: ffList, loadingFF} = useSWR(URLS.FF.list(), fetcher)
    return (
        <>
            <BreadCrumb title1="FF-SME" title2="Home"/>
            <>
            {loadingFF?(<LoaderPage/>):
            (
            <>{(ffList=== undefined || ffList?.length===0)?(<NoTransaction transaction={"FF"}/>):
            (<div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                    <div className="col-md-12">
                              <div className="card o-hidden mb-4">
                                  <div className="card-header d-flex align-items-center border-0 my-header">
                                      <h3 className="w-50 float-left card-title m-0 text-white">Latest Forcasting</h3>
                                      <div className="dropdown dropleft text-right w-50 float-right">
                                          <button className="btn " style={{background:"none"}}id="dropdownMenuButton1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="nav-icon i-Gear-2"></i></button>
                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                              <Link className="dropdown-item" to="/f-forcasting/new">Make new Forcasting</Link>
                                              <Link className="dropdown-item" to="/f-forcasting/list">View All</Link>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="table-responsive">
                                          <table className="table text-center" id="user_table">
                                              <thead>
                                                  <tr>
                                                      <th scope="col">Reference</th>
                                                      <th scope="col">#DSF used</th>
                                                      <th scope="col">#Years of Previsions</th>
                                                      <th scope="col">Plug</th>
                                                      <th scope="col">Date</th>
                                                      <th scope="col">Details</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {ffList?.map((forecast)=>(
                                                      <tr key={forecast?.id}>
                                                          <td><span className="badge badge-info">{forecast?.reference}</span></td>
                                                          <th scope="row">{forecast.dsf_number}</th>
                                                          <td>{forecast.years}</td>
                                                          <td>{forecast.plug}</td>
                                                          <td>{(new Date(forecast?.created_at)).toLocaleString()}</td>
                                                          <td>
                                                            <Link to={`/f-forcasting/details/${forecast?.id}`}><button className="btn btn-primary" type="button" style={{marginRight:10}}><i className="nav-icon i-Eye"></i></button></Link>
                                                          </td>
                                                      </tr>
                                                  ))}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                    </div>
                </div>
            </div>)}
            </>)}
            </> 
        </>
    )
}
export default HomeSMEFForcasting;