import { useState } from "react";
import { API, FFAPI } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const UploadFile = ({name, addFormData, year}) => {
    const [dsfFiles, setDsfFiles] = useState([]);
    const formData = new FormData()
    const addFile=(e)=>{
        let files=dsfFiles;
        let file=e.target
        files.push(e.target.value.file)
        formData.append(file.name, file.files[0], file.name)
    }
    return (
        <div className="card text-left">
            <div className="card-body">
                <h4 className="card-title">Select the DSF file for {year}</h4>
                <div className="dropzone dropzone-area" id="button-select-upload" action="#">
                    <div className="fallback">
                        <input name={name} type="file" accept=".xlsx" onChange={(e)=>{addFormData(e)}}/>
                    </div>
                    <div className="dz-message">Drop Files Here To Upload</div>
                </div>
            </div>
        </div>
    )
}

const SMEFFFormInit = ({setStep, setNumDSF, numDSF, setLoading, setExtractedData, horizon, setHorizon, setIsextracted}) =>{
    const [filesInputs, setFilesInputs ]= useState([]);
    const [formData, setFormData] = useState([])
    const addFormData = (e) => {
        let f_data = formData===null?[]:formData;
        const file=e.target;
        f_data.push(file)
        setFormData(f_data)
    }
    const changeNumDSF=(e)=>{
        // console.log(e?.target.value);
        setNumDSF(e?.target.value)
        if(e.target.value==2){
            // console.log("Here")
            setFilesInputs(["1","2"])
        }else if(e.target.value==3){
            setFilesInputs(["1","2","3"])
        }else if(e.target.value==4){
            setFilesInputs(["1","2","3","4"])
        }else{
            setFilesInputs(["1","2","3","4","5"])
        }
        if(horizon>e.target.value){
            setHorizon(e.target.value)
        }
    }

    const changeHorizon=(e)=>{
        if(e.target.value>numDSF){
            toast.error(`You cannot forecast on more than ${numDSF} years`)
        }else{
            setHorizon(e?.target.value)
        }
    }

    const uploadFile=(e)=>{
        if(formData.length!==filesInputs.length){
            toast.error(`Upload ${filesInputs.length} consecutive files and try again!`, {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            return
        }
        setLoading(true)
            let data = new FormData()
            formData?.forEach((element)=>{
                data.append(element.name,element.files[0]);
            })
            console.log(formData)
            FFAPI.post(URLS.FF.extractData(), data).then((resp2)=>{
                toast.success("Success Extracting Data", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                console.log(resp2.data)
                setExtractedData(resp2.data)
                setIsextracted(true);
                setLoading(false)
            }).catch((error)=>{
            console.log(error)
            toast.error("Error Creating Data", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            setLoading(false);
        })
        e.preventDefault()
    }
   return (
        <div className="col-xl-12 mt-3 mb-3">
            <div className="card text-left o-hidden">
                <div className="card-header d-flex align-items-center border-0 my-header">
                    Basic Forcasting Informations <span className="float-right"><button><i className="nav-icon i-coffee font-weight-bold"></i></button></span>
                </div>
                <div className="card-body" style={{backgroundColor:"#ffff"}}>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mt-4 col-xl">
                                    <label>number of DSF<span className="ml-2 text-danger">*</span></label>
                                    <FontAwesomeIcon icon={faQuestionCircle} data-toggle="tooltip" data-placement="bottom"  title="Describtion in tooltip" className="btn btn-default float-right"/>
                                    <select className="form-control" onChange={(e)=>{changeNumDSF(e)}} value={numDSF}>
                                        <option value="">Select a number</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-4 col-xl">
                                    <label>number of years of forecasting<span className="ml-2 text-danger">*</span></label>
                                    <FontAwesomeIcon icon={faQuestionCircle} data-toggle="tooltip" data-placement="bottom"  title="Describtion in tooltip" className="btn btn-default float-right"/>
                                    <select className="form-control" onChange={(e)=>{changeHorizon(e)}} value={horizon}>
                                        <option value="" defaultChecked>Select a number</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {filesInputs?.map((element,index) => (
                                <div key={index} className="card-body col-4" style={{backgroundColor:"#ffff"}}>
                                    <UploadFile name={`file${element}`} addFormData={addFormData} year={numDSF+index+1}/>
                                </div>
                            ))}
                        </div>
                </div>
                <div className="card-footer">
                    <button className="btn btn-danger float-left" onClick={(e)=>setStep(0)}>Cancle</button>
                    <button className="btn float-right btn-primary" onClick={(e)=>uploadFile(e)}>Validate</button>
                </div>
            </div>
        </div>
    )
}

export default SMEFFFormInit;