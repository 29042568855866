import { useEffect } from "react";
import { curencyFormater } from "../../components/utils/functions";
import { API } from "../../services/axios";
import { toast } from "react-toastify";
import { URLS } from "../../services/urls";
import { saveAs } from 'file-saver'
import axios from "axios";
  
const BillComponent  = ({bill, loading}) =>{
    const formatReference=(ref)=>{
        ref=ref.replace("(","");
        ref=ref.replace(")","");
        return ref
    }  
    const onDownload=()=>{
        // console.log(`Downloading ${bill.ref}`)
        const name = `${bill.ref}/${bill.ref}.pdf`
        loading(true);
        API.post(URLS.PAYMENT.INVOICE.download(),{"name":name})
        .then((res)=>
        {
            // console.log(res.data.filename)
            const blob = new Blob([res.data], {type: res.data['content-type']})
            const href=URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = `data:application/pdf;base64,${res.data.content}`;
            link.setAttribute('download', res.data.filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            loading(false)
        }
        ).catch((err)=>{
            // console.log(err)
            loading(false)
            toast.error("Unable to Download file", {position:toast.POSITION.TOP_CENTER});
        })
    }
    // const reference=`#${bill.ref}`
    const reference=`#${formatReference(bill.ref)}`
    // console.log(reference)
    const status = bill.paye==="1"?"paid":"brouillon"
    const creationDAte = new Date(bill.date_creation*1000)
    let classname = bill.paye==="1"?"text-default collapsed badge badge-success m-2":"text-default collapsed badge badge-danger m-2";
    return (
            <div className="card">
                <div className="card-header header-elements-inline" style={{width:"100%"}}>
                    {/* <p>Pending</p> */}
                    <div className="card-title mb-0" style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                        <a className={classname} data-toggle="collapse" href={reference} aria-expanded="false" style={{padding:15, minWidth:150}}>
                            <span><i className="i-Add-File ul-accordion__font"> </i><span className="mr-3">{status}</span></span>
                        </a>
                        <a className="text-default collapsed" data-toggle="collapse" href={reference} aria-expanded="false"style={{padding:15}}>
                            <span>{curencyFormater(bill.total_ttc)}</span>
                        </a>
                        <a className="text-default collapsed" data-toggle="collapse" href={reference} aria-expanded="false"style={{padding:15}}>
                        <span>{creationDAte.toDateString()}</span></a>
                        <a className="text-default collapsed" data-toggle="collapse" href={reference} aria-expanded="false"style={{padding:15}}>
                            <span className="_dot _inline-dot bg-primary"></span><span className="_dot _inline-dot bg-primary"></span><span className="_dot _inline-dot bg-primary"></span>
                        </a>
                    </div>
                </div>
                <div className="collapse" id={formatReference(bill.ref)} data-parent="#accordionRightIcon">
                    <div className="card-body">
                        {/* {status==="paid" && */}
                        <div className="d-sm-flex mb-5" data-view="print"><span className="m-auto"></span>
                            <button className="btn btn-primary mb-sm-0 mb-3 print-invoice" onClick={()=>{onDownload()}}>Print Invoice</button>
                        </div>
                        {/*  } */}
                        <div id="print-area">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="font-weight-bold">Order Info</h4>
                                    <p>{bill.ref}</p>
                                </div>
                                <div className="col-md-6 text-sm-right">
                                    <p><strong>Order status: </strong>{status}</p>
                                    <p><strong>Order date: </strong>{creationDAte.toDateString()}</p>
                                </div>
                            </div>
                            <div className="mt-3 mb-4 border-top"></div>
                            <div className="row">
                                <div className="col-md-12 table-responsive">
                                    <table className="table table-hover mb-4">
                                        <thead className="bg-gray-300">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Unit Price</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bill.lines.map((line, index)=>(
                                                <tr key={line.id}>
                                                    <th scope="row">{index+1}</th>
                                                    <td>{line.product_label}</td>
                                                    <td>{curencyFormater(line.multicurrency_subprice)}</td>
                                                    <td>{line.qty}</td>
                                                    <td>{curencyFormater(line.total_ht)}</td>
                                                    {/* <td>{parseFloat(line.total_ttc).toFixed(2)}</td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <div className="invoice-summary">
                                        <p>Sub total: <span>{curencyFormater(bill.total_ht)}</span></p>
                                        <p>Vat: <span>{curencyFormater(bill.total_tva)}</span></p>
                                        <h5 className="font-weight-bold">Grand Total: <span>{curencyFormater(bill.total_ttc)}</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    );
}

export default BillComponent;