import { useState } from "react"

const ParamsWithOptions = ({parameters, setParameter, plug}) => {
    const [haveMinCMS, setHaveMinCMS] = useState(false);
    const [cashMinAbsolute, setcashMinAbsolute] = useState(false);
    const [constraintOnDivGrowth, setConstraintOnDivGrowth] = useState(false);
    const [calAccDeprCurrAss, setCalAccDeprCurrAss] = useState(false);

    return (
        <>
         <div className="card" style={{padding:25}}>
              {/* <div className="card-body"> */}
                <div className="row">
                    <label className="switch pr-5 switch-primary mt-2 mb-4 float-center">
                        <input type="checkbox" checked={haveMinCMS} onChange={()=>{setHaveMinCMS(!haveMinCMS)}}/><span className="slider"></span>
                        <span style={{color:"black"}}>Do you have a minimum value for Cash and Marketable Securities?</span>
                    </label>
                </div>{
                haveMinCMS&&
                    <div className="row mt-1">
                        <label className="switch pr-5 switch-primary mt-2 mb-4 float-center">
                            <input type="checkbox" checked={cashMinAbsolute} onChange={()=>{setcashMinAbsolute(!cashMinAbsolute)}}/><span className="slider"></span>
                            <span style={{color:"black"}}>Is you cms in absolute value?</span>
                        </label>
                        {
                            cashMinAbsolute?
                            <div className="row">
                                <div className="mb-4 col float-left">
                                    <label>Provide the minimum value of cash and marketable securities: <span className="ml-2 text-danger text-14">*</span></label>
                                    <input type="number" onChange={(e)=>{setParameter('cashMin',parseFloat(e.target.value));setParameter('kCashMin',0.0)}} value={parameters['cashMin']}/>
                                </div>
                            </div>:
                            <div className="row">
                                <div className="mb-4 col float-left">
                                    <label>Provide the value of cash and marketable securities in percentage of sales: <span className="ml-2 text-danger text-14">*</span></label>
                                    <input type="number" onChange={(e)=>{setParameter('kCashMin',parseFloat(e.target.value));setParameter('cashMin',0.0)}} value={parameters['kCashMin']}/>
                                </div>
                            </div>
                        }
                    </div>}
                <div className="row">
                    <label className="switch pr-5 switch-primary mt-2 mb-4 float-center">
                        <input type="checkbox" checked={constraintOnDivGrowth} onChange={()=>{setConstraintOnDivGrowth(!constraintOnDivGrowth)}}/><span className="slider"></span>
                        <span style={{color:"black"}}>Do we have constraint on the growth of dividendes?</span>
                    </label>
                </div>
                {
                   constraintOnDivGrowth&&
                   <div className="row">
                       <div className="mb-4 col float-left">
                           <label>Provide the maximum growth rate of dividend authorized (between 0 and 100)<span className="ml-2 text-danger text-14">*</span></label>
                           <input type="number" onChange={(e)=>{setParameter('kDiv',parseFloat(e.target.value));setParameter('kDiv',0.0)}} value={parameters['kDiv']}/>
                       </div>
                   </div>
                }
                <div className="row">
                    <label className="switch pr-5 switch-primary mt-2 mb-4 float-center">
                        <input type="checkbox" checked={calAccDeprCurrAss} onChange={()=>{setCalAccDeprCurrAss(!calAccDeprCurrAss)}}/><span className="slider"></span>
                        <span style={{color:"black"}}>Do you want the calculation of Accumulated Depreciation Current Asset to be strict?</span>
                    </label>
                </div>
             {/* </div> */}
         </div>
    </>)
}
export default ParamsWithOptions