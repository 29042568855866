import { Link } from "react-router-dom";
import BreadCrumb from "../../../components/breadcrumb"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatLinkString, getUserDetails, isCompany, setUserDetails } from "../../../components/utils/functions";
import { DEFAULT_PROFILE } from "../../../components/utils/consts";
import { useForm } from "react-hook-form";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import LoaderPage from "../../../components/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import { inLineSelectStyles } from "../../../components/utils/reactSelect";
import { updateUserData } from "../../../store/slices/auth";
import axios from "axios";
import { toast } from "react-toastify";
import { date } from "yup";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { logout } from "../../../services/logout";
// const fetcher = (url) => API.get(url).then(res => res.data);
const cityFetcher = (url) => API.get(url).then((res)=>{return res.data.results})
const countryFetcher = (url) => API.get(url).then((res) =>{return res.data.results}); 

const UserProfileSettings = () =>{
    const {t} = useTranslation();
    const {data: countries, isLoadingC} = useSWR(URLS.PARAMS.COUNTRY.ohada(20), countryFetcher);
    const {data: cities, isLoadingCC} = useSWR(URLS.PARAMS.COUNTRY.CYTIES.ohada(1000), cityFetcher);
    const {
        auth,
    } = useSelector((state)=>state.auth)
    const [userId, setUserId] = useState(null)
    const [loadingUser, setLoadingUser] = useState(true);
    const [loading, setLoading] = useState(false); 
    const [displayPassword, setDisplayPassword] = useState(false); 
    const [displayNewPassword, setDisplayNewPassword] = useState(false);
    const [password, setPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [newPassword2, setNewPassword2] = useState("");
    const [user, setUser] = useState(null);
    const [picture, setPicture] = useState();
    const [preview, setPreview] = useState("");
    const [noPassword, setnoPassword] = useState(false);
    const [passwordValid, setPasswordValid] = useState(true);
    const [equalPassword, setEqualPassword] = useState(true);
    const dispatch = useDispatch();
    const { register, handleSubmit, reset } = useForm();
    const {
        register: registerEnterprise,
        handleSubmit: handleEnterpriseSubmit,
        reset: resetEnterprise
    } = useForm();

    const validatePassword = (password) => {
        const hasUpperCase  =  /[A-Z]+/.test(password);
        const hasLowerCase  =  /[a-z]+/.test(password);
        const hasNumeric  =  /[0-9]+/.test(password);
        return password && hasLowerCase && hasUpperCase && hasNumeric;
    }
    const onUpdatePassword = () => {
        setPasswordValid(true);
        setEqualPassword(true);
        setnoPassword(false);
        if(!password){
            toast.error(t("textNoOldPwError"), {position:toast.POSITION.TOP_CENTER, theme:"colored"})
            setnoPassword(true)
            return;
        }
        if(!validatePassword(newPassword)){
            setPasswordValid(false)
            toast.error(t("textInvOldPwError"), {position:toast.POSITION.TOP_CENTER, theme:"colored"});
            return;
          }
        if(newPassword!==newPassword2){
            toast.error(t("textNotEqualPw"), {position:toast.POSITION.TOP_CENTER, theme:"colored"})
            setEqualPassword(false)
            return;
        }
        setLoading(true);
        const data = {
            old_password: password,
            new_password1: newPassword,
            new_password2: newPassword2
        }
        API.post(URLS.AUTHS.PASSWORD.change,data).then(
            (resp)=>{
                toast.success(t("textPwUpdated"), {position:toast.POSITION.TOP_CENTER, theme:"colored"})
                setLoading(false)
                logout();
            }
        ).catch(
            (error)=>{
                toast.error(t(error.response?.data?.msg)??"Error Updating password", {position:toast.POSITION.TOP_CENTER, theme:"colored"})
                setLoading(false)
                console.log(error.response.data)
            }
        )    
    }
    const onSubmit=(data)=>{
        setLoading(true);
        let data1 = data;
        let formData = new FormData();
        formData.append("first_name", data?.firstName);
        formData.append("last_name", data?.lastName);
        formData.append("mobile", data?.mobile);
        formData.append("secondary_email", data?.secondaryEmail);
        formData.append("zip_code", data?.zipCode);
        formData.append("address", data?.address);
        formData.append("phone_number", data?.phoneNumber);
        formData.append("language", data?.language);
        formData.append("profession", data?.profession);
        formData.append("gender", data?.gender);
        formData.append("city", data?.city);
        formData.append("country", data?.country);
        formData.append("enterprise", data?.enterprise);
        if(picture){
            formData.append("picture", picture)
        }
        API.patch(URLS.USERS.update(auth?.user?.id),formData)
        .then((resp)=>{
            const user = getUserDetails();
            const { data } = resp;
            setUserDetails({
                ...user,
                first_name: data.first_name,
                last_name: data.last_name,
                phone_number: data.phone_number,
                gender: data.gender,
                zip_code: data.zip_code,
                picture: data.picture?? user?.picture,
            });
            dispatch(updateUserData({ ...data, groups: user.groups }));
            setLoading(false)
            toast.success("Profile successfully Updated", {position:toast.POSITION.TOP_CENTER})
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
          console.log(error)
        });
    }

    const onSubmitEnterprise=(data)=>{
        console.log(data?.city)
        setLoading(true)
        let formData = new FormData();
        formData.append("name", data?.name);
        formData.append("designation", data?.designation);
        formData.append("niu", data?.niu);
        formData.append("rccm", data?.rccm);
        formData.append("country", data?.country);
        formData.append("city", data?.city);
        formData.append("vta_num", data?.vta_num);
        formData.append("agreement", data?.agreement);
        API.post(URLS.USERS.updateEnterprise(userId),formData)
        .then((resp)=>{
            setLoading(false)
            toast.success("Enterprise data successfully uploaded", {position: toast.POSITION.TOP_CENTER})
        }).catch((error)=>{
            setLoading(false)
            toast.error(error?.response?.data?.detail??"Unable to upload enterprise data")
            console.log(error)
        })
    }
    useEffect(()=>{
        API.get(URLS.AUTHS.me).then((resp)=>{
                const {data} = resp;
                // console.log(data.city?.url?.split("/")[data.city?.url?.split("/").length-2])
                console.log(data?.id)
                setUserId(data?.id);
                reset({
                    firstName: data?.first_name ?? "",
                    lastName: data?.last_name ?? "",
                    email: data?.email ?? "",
                    phoneNumber: data?.phone_number ?? "",
                    language: data?.language ?? "eng",
                    age: data?.age ?? "",
                    gender: data?.gender ?? "",
                    secondaryEmail: data?.secondary_email?? "",
                    country: data?.country?.url?.split("/")[data.city?.url?.split("/").length-2]?? "",
                    city: data?.city?.url?.split("/")[data.city?.url?.split("/").length-2]?? "",
                    zipCode: data?.zip_code?? "",
                    address: data?.address?? "",
                    mobile: data?.mobile?? "",
                    profession: data?.profession?? "",
                    enterprise: data?.enterprise?.name?? ""
                });
                resetEnterprise({
                    // id: data?.enterprise?.id?? "",
                    name: data?.enterprise?.name?? "",
                    designation: data?.enterprise?.designation?? "",
                    country: data?.country?.url?.split("/")[data.city?.url?.split("/").length-2]?? "",
                    city: data?.city?.url?.split("/")[data.city?.url?.split("/").length-2]?? "",
                    niu: data?.enterprise?.niu?? "",
                    rccm: data?.enterprise?.rccm?? "",
                    vta_num: data?.enterprise?.vta_num?? "",
                    agreement:  data?.enterprise?.agreement?? ""
                })
                if(data?.picture){
                    if (data?.picture) {
                        if (data?.picture?.includes("https://")) {
                        setPreview(data?.picture);
                        } else {
                        setPreview(URLS.AUHT_URL + data?.picture);
                        }
                    } else {
                        setPreview(DEFAULT_PROFILE);
                    }
                }
                setLoadingUser(false);
            }).catch((error)=>{
            if(auth){
                const {user} = auth;
            reset({
                firstName: user?.first_name ?? "",
                lastName: user?.last_name ?? "",
                email: user?.email ?? "",
                phoneNumber: user?.phone_number ?? "",
                language: user?.language ?? "eng",
                gender: user?.gender ?? "",
                secondaryEmail: user?.secondary_email?? "",
                country: user?.country?? "",
                city: user?.city?? "",
                zipCode: user?.zip_code?? "",
                address: user?.address?? "",
                mobile: user?.mobile?? "",
                profession: user?.profession?? "",
                enterprise: user?.enterprise?.name?? ""
            });
            resetEnterprise({
                name: user?.enterprise?.name?? "",
                designation: user?.enterprise?.designation?? "",
                country: user?.enterprise?.country?? "",
                niu: user?.enterprise?.niu?? "",
                rccm: user?.enterprise?.rccm?? ""
            });
            if(user?.picture){
                if (user?.picture) {
                    if (user?.picture?.includes("https://")) {
                      setPreview(user?.picture);
                    } else {
                      setPreview(URLS.AUHT_URL + "/" + user?.picture);
                    }
                  } else {
                    setPreview(DEFAULT_PROFILE);
                  }
            }
            }
            setLoadingUser(false);
        })
    },[auth])
    const handleChangeProfile = (event) => {
        setPicture(event?.target?.files[0]);
        setPreview(URL.createObjectURL(event?.target?.files[0]));
      };
      const togglePwdVisibility = () => {
        setDisplayPassword(!displayPassword);
    };
    const toggleNewPwdVisibility = () => {
      setDisplayNewPassword(!displayNewPassword);
  };
    return (
        <>
            <BreadCrumb title1="User Profile" title2="Settings" link=""/>
            {(loadingUser || isLoadingC || isLoadingCC)?(
                <LoaderPage/>
            ):(
            <div className={loading?"row disabled":"row"}>
                <div className="col-xl-6">    
                    <div className="card mb-5">
                        <div className="card-header my-header">
                            <h3 className="ul-widget__head-title my-header">Profile Settings</h3>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="ul-widget1">
                                    <div className="ul-widget4__item ul-widget4__users">
                                    <div className="ul-widget4__img">
                                        <img id="userDropdown" src={preview} alt={auth?.user?.first_name} style={{width:100, height:100}} data-toggle="dropdown"/>
                                    </div>
                                    <div className="ul-widget2__info ul-widget4__users-info"><span className="ul-widget2__title">Change Your Avatar</span>
                                    <span className="ul-widget2__username" href="#">Maximum file size allowed is 200k</span></div>
                                    <div className="ul-widget4__actions">
                                        <input type="file" id="inputAvatar" hidden onChange={handleChangeProfile}/>{" "}<label className="btn btn-primary" htmlFor="inputAvatar">Choose a picture</label>
                                    </div>
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">First name</label>
                                        <input className="form-control form-element" id="user-name" {...register("firstName")}/>
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">First name</label>
                                        <input className="form-control form-element" id="user-name" {...register("lastName")}/>
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Email </label>
                                        <input className="form-control form-element" id="email" {...register("email")} placeholder="user@bkresearhandanalytics.com" disabled={true}/>
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Secodary Email</label>
                                        <input className="form-control form-element" id="secondary-email" {...register("secondaryEmail")} placeholder="secondemail@bkresearhandanalytics.com"/>
                                        <label style={{fontWeight:200}}>This email may be used to recover your account</label>
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Phone Number </label>
                                        <input className="form-control form-element" id="phone-number" {...register("phoneNumber")} placeholder="690234578"/>
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Mobile</label>
                                        <input className="form-control form-element" id="mobile" {...register("mobile")} placeholder="222222222"/>
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Gender </label>
                                        <select className="form-control form-element"
                                            {...register("gender")}>
                                                <option value="MALE">Male</option>
                                                <option value="FEMALE">Female</option>
                                            </select>{" "}
                                        
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Language</label>
                                        <select className="form-control form-element"
                                            {...register("language")}>
                                                <option value="EN">English</option>
                                                <option value="FR">French</option>
                                            </select>{" "}
                                        <label style={{fontWeight:200}}>This is the language we use to communicate with You.<br/>It is different from Your site naviagtion language</label>
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Country </label>
                                        <select 
                                            className="form-control form-element"
                                            {...register("country")}>
                                            {countries?.map((count, index)=>(
                                                <option key={index} value={count.id}>{count.name}</option>
                                            ))}
                                            </select>{" "}
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">City</label>
                                        <select className="form-control form-element"
                                            {...register("city")}>
                                            {cities?.map((city, index)=>(
                                                <option key={index} value={city.id}>{city.name}</option>
                                            ))}
                                            </select>{" "}
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Address </label>
                                        <input className="form-control form-element" id="address" {...register("address")} placeholder="Male"/>
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Zip Code</label>
                                        <input className="form-control form-element" id="zip-code" {...register("zipCode")} placeholder="5432"/>
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Profession </label>
                                        <input className="form-control form-element " id="profession" {...register("profession")} placeholder="Male"/>
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label className="label-text">Enterprise</label>
                                        <input className="form-control form-element" id="enterprise" {...register("enterprise")} placeholder="B.K. Research & Analytics"/>
                                    </div>
                                </div>
                                <div className="separator-breadcrumb border-top"></div>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <button type="submit" className="btn my-header">Update Your Profile Settings</button>
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        {/* <button type="submit" className="btn btn-danger" style={{float:"right"}}>Cancel</button> */}
                                    </div>
                                </div>
                            </form>
                        </div> 
                    </div>
                </div>
                <div className="col-xl-6"> 
                    {isCompany() && 
                        (<div className="card mb-5">
                            <div className="card-header my-header">
                                <h3 className="ul-widget__head-title my-header">Enterprise Settings</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleEnterpriseSubmit(onSubmitEnterprise)}>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">Name </label>
                                            <input className="form-control form-element" id="name" {...registerEnterprise("name")} placeholder="BKR"/>
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">Designation</label>
                                            <input className="form-control form-element" id="designation" {...registerEnterprise("designation")} placeholder="B.K. Research & Analytics"/>
                                        </div>
                                    </div>
                                    <div className="separator-breadcrumb border-top"></div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">N.I.U </label>
                                            <input className="form-control form-element" id="niu" {...registerEnterprise("niu")} placeholder="BKR"/>
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">RCCM</label>
                                            <input className="form-control form-element" id="rccm" {...registerEnterprise("rccm")} placeholder="B.K. Research & Analytics"/>
                                        </div>
                                    </div>
                                    <div className="separator-breadcrumb border-top"></div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">Country </label>
                                            <select 
                                            className="form-control form-element"
                                            {...registerEnterprise("country")}>
                                            {countries?.map((count, index)=>(
                                                <option key={index} value={count.id}>{count.name}</option>
                                            ))}
                                            </select>{" "}
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">City </label>
                                            <select 
                                            className="form-control form-element"
                                            {...registerEnterprise("city")}>
                                            {cities?.map((city, index)=>(
                                                <option key={index} value={city.id}>{city.name}</option>
                                            ))}
                                            </select>{" "}
                                        </div>
                                    </div>
                                    <div className="separator-breadcrumb border-top"></div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">V.A.T Number </label>
                                            <input className="form-control form-element" id="vta_num" {...registerEnterprise("vta_num")} placeholder="19"/>
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="label-text">agreement</label>
                                            <input className="form-control form-element" id="rccm" {...registerEnterprise("agreement")} placeholder="N°: 17-08234/L/MINEFI/SG/DDES/ESUP/SDA/OAGS du 20 Septembre 2017"/>
                                        </div>
                                    </div>
                                    <div className="separator-breadcrumb border-top"></div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <button type="submit" className="btn my-header">Update Your Enterprise Settings</button>
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            {/* <button type="submit" className="btn btn-danger" style={{float:"right"}}>Cancel</button> */}
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>)
                    }
                    <div className="card mb-5">
                        <div className="card-header my-header">
                            <h3 className="ul-widget__head-title my-header">Update Your Password</h3>
                        </div>
                        <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 form-group mb-3">
                                {noPassword?(<label className="text-danger">Enter Your former password</label>):
                                (<label className="label-text">Type Your Actual password</label>)}
                                <div className="input-group">
                                    <input className="form-control form-element" 
                                        id="pwd"
                                        type={displayPassword ? 'text' : 'password'}
                                        required=""
                                        placeholder="************" 
                                        name="pwd"
                                        onChange={(e)=>{setPassword(e.target.value)}}
                                        style={{height:"43px"}}
                                        autoComplete={"search"}/>
                                    <div className="input-group-append form-element" onClick={togglePwdVisibility} style={{cursor:"pointer",minHeight:"43px"}}>
                                        <span  className="eye-icon input-group-text">
                                        <FontAwesomeIcon icon={displayPassword? faEye: faEyeSlash } /></span>
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 form-group mb-3">
                                {!passwordValid?(<label className="text-danger">Your password Should be 8 characters at least</label>):
                                (<label className="label-text">Provide Your New password</label>)}
                                <div className="input-group">
                                <input className="form-control form-element" id="npwd"
                                        type={displayNewPassword ? 'text' : 'password'}
                                        required=""
                                        placeholder="************" 
                                        name="new-password"
                                        autoComplete={"search"}
                                        onChange={(e)=>{setNewPassword(e.target.value)}}
                                        style={{height:"43px",}}/>
                                    <div className="input-group-append form-element" onClick={toggleNewPwdVisibility} style={{cursor:"pointer",minHeight:"43px"}}>
                                        <span  className="eye-icon input-group-text"><FontAwesomeIcon icon={displayNewPassword? faEye: faEyeSlash } /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                {!equalPassword?(<label className="text-danger">Password Not Equal</label>):
                                (<label className="label-text ">Retype Your New password</label>)}
                                <div className="input-group">
                                <input className="form-control form-element" id="npwd2"
                                        type={displayNewPassword ? 'text' : 'password'}
                                        required=""
                                        placeholder="************" 
                                        autoComplete={"off"}
                                        name="new-password2"
                                        onChange={(e)=>{setNewPassword2(e.target.value)}}
                                        style={{height:"43px"}}/>
                                    <div className="input-group-append form-element" onClick={toggleNewPwdVisibility} style={{cursor:"pointer",minHeight:"43px"}}>
                                        <span  className="eye-icon input-group-text"><FontAwesomeIcon icon={displayNewPassword? faEye: faEyeSlash } /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator-breadcrumb border-top"></div>
                        <div className="row">
                            <div className="col-md-6 form-group mb-3">
                                <button type="submit" className="btn my-header" onClick={(e)=>{onUpdatePassword()}}>Update Your Password</button>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>)}
        </>
    );
}

export default UserProfileSettings;