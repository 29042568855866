import { Link } from "react-router-dom"
import useSWR from "swr";
import { useState } from "react";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import MPieChart from "../../components/chart/PieChart";
import BreadCrumb from "../../components/breadcrumb";
import MBarChart from "../../components/chart/BarChart";
import NoTransaction from "../../components/no-transaction/emptypage";
import { useTranslation } from "react-i18next";
import { mapNumToMonth, percentageFormater } from "../../components/utils/functions";
import { API } from "../../services/axios";
const fetcher = (url ) => API.get(url).then((res)=>{
  console.log(res.data.results)
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  console.log(new Date(res.data.results[0].created_at).getFullYear())
  console.log(new Date(res.data.results[0].created_at).getFullYear()===year-0 && new Date(res.data.results[0].created_at).getMonth()===month-0)
  let repartition = [];
  let period = []
  for(let i=0; i<12; i++){
    const newDate = new Date(year, month-i)
    const count = res.data.results.filter((item) => {return new Date(item.created_at).getFullYear()===newDate.getFullYear() && new Date(item.created_at).getMonth()===newDate.getMonth()}).length;
    repartition.push(count);
    period.push([mapNumToMonth(newDate.getMonth()),newDate.getFullYear()])
  }
  const resp = {
    "results": res.data.results,
    "repartitions": repartition,
    "period": period
  }
  console.log(resp);
  return resp;
})
const HomeSMECRR = () => {
  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const { data: ratings, isLoading} = useSWR(URLS.CRR.list(page), fetcher);
  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };
  console.log(ratings)
    return (
        <>
          {isLoading?(<LoaderPage/>):(
            <>
              <BreadCrumb title1="CRR-SME" title2="Dashboard"/>
              {ratings===undefined || ratings?.results?.length===0?(
                <NoTransaction transaction={"CRR"}/>
              ):(
              <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                          <div className="card o-hidden mb-4">
                              <div className="card-header d-flex align-items-center border-0 my-header">
                                  <h3 className="w-50 float-left card-title m-0 text-white">{t("OTHERCRRINFO.LASTCRR")}</h3>
                                    <div className="dropdown dropleft text-right w-50 float-right">
                                      <button className="btn " style={{background:"none"}}id="dropdownMenuButton1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="nav-icon i-Gear-2"></i></button>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                          <Link className="dropdown-item" to={`/sme-crr/details/${ratings?.results[0]?.id}`}>{t("textView")}</Link>
                                      </div>
                                    </div>
                              </div>
                              <div>
                                <div className="table-responsive">
                                    <table className="table text-left" id="user_table">
                                        <tbody>
                                          <tr>
                                            <td className="float" colSpan={2}>
                                              <Link to={`${String(URLS.API_URL).replace('/v1','')}/sme_crr/media/rating_reports/${String(ratings?.results[0]?.report).split('/').pop()}`} target="_blank">
                                                <button className="btn my-header">{t("txtDownloadRep")}</button>
                                              </Link>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="" style={{float:""}}>date</td>
                                            <td style={{textAlign:"right"}}>{(new Date(ratings?.results[0]?.created_at)).toLocaleString()}</td>
                                          </tr>
                                          <tr>
                                            <td className="float">{t("OTHERCRRINFO.REF")}</td>
                                            <td style={{textAlign:"right", textTransform:"uppercase"}}>{ratings?.results[0]?.reference}</td>
                                          </tr>
                                          <tr>
                                            <td className="float">{t("OTHERCRRINFO.CSCORE")}</td>
                                            <td style={{textAlign:"right"}}>{ratings?.results[0]?.grade}</td>
                                          </tr>
                                          <tr>
                                            <td className="float">{t("OTHERCRRINFO.DEFAULTPROB")}</td>
                                            <td style={{textAlign:"right"}}>{percentageFormater(ratings?.results[0]?.probability_qualitative)}</td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-12">
                      <div className="card mb-4 o-hidden">
                          <div className="card-body ul-card__widget-chart">
                              <div className="ul-widget__chart-info my-header">
                                  <h5 className="heading text-white">{t("OTHERCRRINFO.CRRBREAKDOWN")}</h5>
                              </div>
                              <MPieChart data={[
                                      {
                                      value: ratings?.results?.filter((item)=>String(item.grade).toUpperCase()==="D").length,
                                      name: `D`,
                                      },
                                      {
                                      value: ratings?.results?.filter((item)=>String(item.grade).toUpperCase()==="C").length,
                                      name: `C`
                                      },
                                      {
                                      value: ratings?.results?.filter((item)=>String(item.grade).toUpperCase()==="B").length,
                                      name: `B`,
                                      },
                                      {
                                      value: ratings?.results?.filter((item)=>String(item.grade).toUpperCase()==="A").length,
                                      name: `A`,
                                      },
                                  ]}/>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                      <div className="row">
                          <div className="col-md-12">
                              <div className="card o-hidden mb-4">
                                  <div className="card-header d-flex align-items-center border-0 my-header">
                                      <h3 className="w-50 float-left card-title m-0 text-white">{t("OTHERCRRINFO.LATECRR")}</h3> 
                                      <div className="dropdown dropleft text-right w-50 float-right">
                                          <button className="btn " style={{background:"none"}}id="dropdownMenuButton1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="nav-icon i-Gear-2"></i></button>
                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                              <Link className="dropdown-item" to="/sme-crr/new">{t("textNew")}</Link>
                                              <Link className="dropdown-item" to="/sme-crr/list">{t("textViewAll")}</Link>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="table-responsive">
                                          <table className="table text-center" id="user_table">
                                              <thead>
                                                  <tr>
                                                      <th scope="col">{t("OTHERCRRINFO.CSCORE")}</th>
                                                      <th scope="col">{t("OTHERCRRINFO.REF")}</th>
                                                      <th scope="col">{t("RATINGS_DATA.INDICATOR")}</th>
                                                      <th scope="col">{t("textYear")}</th>
                                                      <th scope="col">{t("RATINGS_DATA.DATE")}</th>
                                                      <th scope="col">{t("RATINGS_DATA.DETAILS")}</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {ratings?.results?.map((rating)=>(
                                                      <tr key={rating.id}>
                                                          <td><span className="badge badge-round-info">{rating.grade}</span></td>
                                                          <th scope="row">{rating.reference}</th>
                                                          <td>{rating.indicator}</td>
                                                          <td>{rating.year}</td>
                                                          <td>{(new Date(rating.created_at)).toLocaleString()}</td>
                                                          <td>
                                                            <Link to={`/sme-crr/details/${ratings?.results[0]?.id}`}><button className="btn btn-primary" type="button" style={{marginRight:10}}><i className="nav-icon i-Eye"></i></button></Link>
                                                          </td>
                                                      </tr>
                                                  ))}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-12 col-sm-12">
                              <div className="card mb-4 o-hidden">
                                  <div className="card-body ul-card__widget-chart">
                                      <div className="ul-widget__chart-info my-header">
                                          <h5 className="heading text-white">{t("OTHERCRRINFO.MONTHCRR")}</h5>
                                      </div>
                                      <MBarChart 
                                      data_axis={ratings?.period} 
                                      data_seriels={ratings?.repartitions}
                                      t={t}/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              )}
            </>
          )}
        </>
    );
}
export default HomeSMECRR;